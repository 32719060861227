import React, { FC, useState, useMemo, useRef } from "react";
import { Row, Col, Button, Drawer, Avatar ,Whisper, IconButton, Tooltip } from "rsuite";
import { Icon } from "@rsuite/icons";
import { FaRegEdit } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import { PageContainer, PageHeader, PageBody } from "../../components/layout";
import { FileProvider } from "./FileContext";
import { useFilePage } from "./UseFilePage";
import { SelectColumn } from "react-data-grid";
import type { Column } from "react-data-grid";
import AcPageList from "../../components/acComponents/pageList";
import { FileUploadForm, formMethods } from "./FileUploadForm";

export interface FilemanagerPageProps {}

export const FilemanagerPage: FC<FilemanagerPageProps> = props => {
  const useFilePageMethods = useFilePage({ businessCalendar: "test" });
  const [selectedFileInfo, setSelectedFileInfo] = useState<Set<number>>(() => new Set());
  const {
    isLoading,
    fileInfoPageData,
    limitPerPage,
    activePage,
    setLimitPerPage,
    setActivePage,
    deleteFiles,
  } = useFilePageMethods;

  const formRef = useRef<formMethods>(null);
  const [smsTemplateFormValue, setSmsTemplateFormValue] = useState<any>();
  const [sortDirection, setSortDirection] = useState<any>();
  const [open, setOpen] = React.useState(false);

  const getHostUrl = useMemo((): any => {
    return process.env.REACT_APP_IMAGE_URL;
  }, []);


  const copyText = async (clickObj: any) => {
    const textToCopy = getHostUrl + "/" + clickObj?.row?.fileUrl;
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const columns = useMemo((): Column<any>[] => {
    return [
      SelectColumn,
      { key: "id", name: "Id", width: 35 },
      { key: "fileName", name: "Name", resizable: true },
      { key: "fileDescription", name: "Description", resizable: true },
      {
        key: "image",
        name: "Image",
        formatter(obj: any) {
          return (
            <Avatar src={getHostUrl + "/" + obj?.row?.fileUrl} alt="@superman66" />
          );
        },
        resizable: true,
      },
      {
        key: "path",
        name: "File Path",
        formatter(obj: any) {
          return (  
              <div onClick={() => copyText(obj)} >{getHostUrl + "/" + obj?.row?.fileUrl}</div> 
          );
        },
        resizable: true,
      },
      {
        key: "Copy",
        name: "Copy",
        formatter(obj: any) {
          return (
            <>
              <Whisper placement="top" trigger="hover" speaker={<Tooltip>Copy</Tooltip>}>
               <IconButton
                  size="xs"
                  appearance="primary"
                  onClick={() => copyText(obj)}
                  icon={<Icon as={FaCopy} />}
                />     
              </Whisper>
            </>
          );
        },
        resizable: true,
      },
      // { key: "Action", name: "Action", width: 180, resizable: true },
    ];
  }, []);

  const setFormValue = (clickObj: any) => {
    setOpen(true);
    setSmsTemplateFormValue(clickObj);
  };

  const createOrUpdateHandle = async () => {
    if (formRef?.current) {
      const status = await formRef.current.saveFiles(smsTemplateFormValue?.type);
      if (status) {
        setOpen(false);
      }
    }
  };

  const handleDeleteByIds = async () => {
    let selectedIds = Array.from(selectedFileInfo);

    const status = await deleteFiles(selectedIds);
    if (status) {
      setOpen(false);
    }
  };

  const pageHeader = (
    <Row className="show-grid" style={{ height: "inherit" }}>
      <Col xs={6}>
        <h3 className="title">File Manager </h3>
      </Col>
    </Row>
  );

  return (
    <FileProvider {...useFilePageMethods}>
      <PageContainer>
        <PageHeader>{pageHeader}</PageHeader>
        <PageBody>
          <Row style={{ marginBottom: "10px" }}>
            <Col xs={6}>
              <Button
                onClick={() => setFormValue({ type: "new", formObj: undefined })}
                style={{
                  marginRight: "15px",
                  padding: "0px 4px",
                }}
                size="xs"
                appearance="primary"
                disabled={!!selectedFileInfo?.size}
              >
                <i className="fas fa-plus"></i> Upload
              </Button>
              <Button
                onClick={handleDeleteByIds}
                style={{
                  marginRight: "15px",
                  padding: "0px 4px",
                }}
                disabled={!selectedFileInfo?.size}
                size="xs"
                appearance="primary"
              >
                Delete
              </Button>
            </Col>
            <Col xs={18}></Col>
          </Row>
          <AcPageList
            columns={columns}
            data={fileInfoPageData?.content}
            pageInfo={fileInfoPageData}
            setLimitPerPage={setLimitPerPage}
            setActivePage={setActivePage}
            activePage={activePage}
            selected={selectedFileInfo}
            setSelected={setSelectedFileInfo}
            setSortDirection={setSortDirection}
            // setFormValue={setFormValue}
            limitPerPage={limitPerPage}
            pagination={true}
            whenNoData={
              <>
                <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
              </>
            }
            loading={isLoading}
          >
            <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
          </AcPageList>
          <Drawer
            keyboard={true}
            size="sm"
            backdrop={"static"}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <Drawer.Header>
              <Drawer.Title>File Uploader</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <FileUploadForm ref={formRef} formData={smsTemplateFormValue} />

              <Drawer.Actions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    createOrUpdateHandle();
                  }}
                  appearance="primary"
                >
                  {smsTemplateFormValue?.type === "edit" ? "Update" : "Create"}
                </Button>
              </Drawer.Actions>
            </Drawer.Body>
          </Drawer>
        </PageBody>
      </PageContainer>
    </FileProvider>
  );
};

export default FilemanagerPage;
