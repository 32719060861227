import c from "config";
import React, { FC, CSSProperties } from "react";
import DataGrid, { SelectColumn } from "react-data-grid";
import type { Column, RowsChangeData, DataGridHandle } from "react-data-grid";

export interface AcReactDataGridProps {
  heightAdjust?: string;
  columns?: any;
  rows?: any;
  onRowsChange?: any;
  whenNoData?: any;
  setSelected: any;
  callbackSortDirection: (sortDir: any) => void;
  onDoubleClick?: (clickCell: any) => void;
  selectedRows: any;
}

export const AcReactDataGrid: FC<AcReactDataGridProps> = props => {
  const { heightAdjust, onDoubleClick, columns, rows, onRowsChange, selectedRows, setSelected } =
    props;
  const renderStyle = () => {
    const styles: CSSProperties = {};
    styles.width = "auto";
    styles.height = "50%";
    return styles;
  };

  const rowKeyGetter = (row: any) => {
    return row.id;
  };
  // const onDoubleClick = (clickCell: any) => {
  //   console.log(clickCell.row);
  // };

  return (
    <DataGrid
      onCellDoubleClick={onDoubleClick}
      style={renderStyle()}
      rowKeyGetter={rowKeyGetter}
      columns={columns}
      rows={rows}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelected}
      onRowsChange={onRowsChange}
      headerRowHeight={45}
      rowHeight={45}
      className="rdg-light"
      enableVirtualization={false}
      direction="ltr"
    />
    // <>test</>
  );
};
export default AcReactDataGrid;
