import React, { FC, PropsWithChildren } from "react";
import { UseEmailPageMethods } from "./UseEmailPage";

export const EmailContext = React.createContext<UseEmailPageMethods | null>(null);

export const useEmailContext = () => React.useContext(EmailContext) as UseEmailPageMethods;
export const EmailProvider: FC<UseEmailPageMethods> = ({
  children,
  ...props
}: PropsWithChildren<UseEmailPageMethods>) => {
  return <EmailContext.Provider value={{ ...props }}>{children}</EmailContext.Provider>;
};
