import { ApiRestService } from "../../common/ApiRestService";
import { PageRequest } from "../../common/domain/PageRequest";
import AuthStorage from "../../../context/AuthStorage";
export class GroupService extends ApiRestService {
  toHeaders() {
    let headers: Record<string, any> = {};
    headers["Authorization"] = AuthStorage.getAuthorization();
    return headers;
  }

  async findPage(pageRequest: PageRequest = new PageRequest()) {
    const pageReq: PageRequest = pageRequest;
    if (pageRequest.sorts && pageRequest.sorts.length > 0) {
      const sort = pageRequest.sorts[0];
      const sortField = sort[0];
      let sortDirection = "ASC";
      if (sort.length > 1) {
        sortDirection = sort[1].toUpperCase();
      }
      if (!pageReq.filter) pageReq.filter = {};
      pageReq.filter.sortItem = `${sortField}_${sortDirection}`;
    }
    return this.getPage({
      apiName: "contact-page",
      baseURL: "http://localhost:8070/api",
      path: `/group`,
      pageRequest: pageReq,
      // classRef: Contact,
      headers: this.toHeaders(),
    });
  }

  async getAllGroups() {
    return this.getPage({
      apiName: "contact-page",
      baseURL: "http://localhost:8070/api",
      path: `/group/all`,
      headers: this.toHeaders(),
    });
  }

  // http://localhost:8070/api/group/1/customers/all
  async getGroupById(id: number) {
    return this.getPage({
      apiName: "contact-page",
      baseURL: "http://localhost:8070/api",
      path: `/group/${id}/customers/all`,
      headers: this.toHeaders(),
    });
  }

  async create(obj: any) {
    return this.post({
      apiName: "contact-page",
      baseURL: "http://localhost:8070/api",
      path: `/group`,
      body: obj,
      // classRef: Contact,
      headers: this.toHeaders(),
    });
  }
  async update(obj: any) {
    const _body = obj;
    return this.put({
      apiName: "contact-page",
      baseURL: "http://localhost:8070/api",
      path: `/group/${_body?.id}`,
      body: obj,
      // classRef: Contact,
      headers: this.toHeaders(),
    });
  }

  async deleteByIds(selectedIds: any) {
    const _body = {
      ids: selectedIds,
    };
    return this.post({
      apiName: "sms-page-update",
      baseURL: "http://localhost:8070/api",
      path: `/group/delete-selected`,
      body: _body,
      headers: this.toHeaders(),
    });
  }
}
