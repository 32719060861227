import React, { FC, PropsWithChildren } from "react";
import { UseFilePageMethods } from "./UseFilePage";

export const FileContext = React.createContext<UseFilePageMethods | null>(null);

export const useFileContext = () => React.useContext(FileContext) as UseFilePageMethods;
export const FileProvider: FC<UseFilePageMethods> = ({
  children,
  ...props
}: PropsWithChildren<UseFilePageMethods>) => {
  return <FileContext.Provider value={{ ...props }}>{children}</FileContext.Provider>;
};
