import { LoginService } from "./auth/service/LoginService";
import { CustomerService } from "./customer/service/CustomerService";
import { GroupService } from "./customer/service/GroupService";
import { SmsService } from "./customer/service/SmsService";
import { FileService } from "./customer/service/FileService";
import { EmailTemplateService } from "./customer/service/EmailTemplateService";
import { DashBoardService } from "./customer/service/DashBoardService";

let instance: ApiService;

export class ApiService {
  private _loginService?: LoginService;
  private _customerService?: CustomerService;
  private _groupService?: GroupService;
  private _smsService?: SmsService;
  private _emailTemplateService?: EmailTemplateService;
  private _dashBoardService?: DashBoardService;
  private _fileService?: FileService;

  static getInstance() {
    if (!instance) {
      instance = new ApiService();
    }
    return instance;
  }

  constructor() {
    this.loginService = new LoginService();
    this.customerService = new CustomerService();
    this.groupService = new GroupService();
    this.smsService = new SmsService();
    this.emailTemplateService = new EmailTemplateService();
    this.dashBoardService = new DashBoardService();
    this.fileService = new FileService();
  }

  get loginService(): LoginService {
    return this._loginService || new LoginService();
  }

  set loginService(value: LoginService) {
    this._loginService = value;
  }

  get customerService(): CustomerService {
    return this._customerService || new CustomerService();
  }

  set customerService(value: CustomerService) {
    this._customerService = value;
  }

  get groupService(): GroupService {
    return this._groupService || new GroupService();
  }

  set groupService(value: GroupService) {
    this._groupService = value;
  }

  get smsService(): SmsService {
    return this._smsService || new SmsService();
  }

  set smsService(value: SmsService) {
    this._smsService = value;
  }

  get emailTemplateService(): EmailTemplateService {
    return this._emailTemplateService || new EmailTemplateService();
  }

  set emailTemplateService(value: EmailTemplateService) {
    this._emailTemplateService = value;
  }

  get dashBoardService(): DashBoardService {
    return this._dashBoardService || new DashBoardService();
  }

  set dashBoardService(value: DashBoardService) {
    this._dashBoardService = value;
  }

  get fileService(): FileService {
    return this._fileService || new FileService();
  }

  set fileService(value: FileService) {
    this._fileService = value;
  }
}
