import { FC, useState, useMemo } from "react";
import { Row } from "rsuite";
import type { Column } from "react-data-grid";
import AcPageList from "../../components/acComponents/pageList";
import { EmailProvider } from "./EmailContext";
import { useEmailPage } from "./UseEmailPage";
import moment from "moment-timezone";

export interface DashboaedEmailProps {}
export const DashboaedEmail: FC<DashboaedEmailProps> = props => {
  const useEmailPageMethods = useEmailPage({ businessCalendar: "test" });
  const { isLoading, emailPageData, limitPerPage, setLimitPerPage, activePage, setActivePage } =
    useEmailPageMethods;

  const [selectedCustomers, setSelectedCustomers] = useState<Set<number>>(() => new Set());
  const [sortDirection, setSortDirection] = useState<any>();

  const dateFormat = (date: any, pattern = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(date).format(pattern);
  };

  const columns = useMemo((): Column<any>[] => {
    return [
      { key: "id", name: "Id", width: 30 },
      { key: "customerEmail", name: "Email", width: 180, resizable: true },
      { key: "customerName", name: "Customer Name", width: 180, resizable: true },
      { key: "groupName", name: "Group Name", width: 200, resizable: true },
      { key: "templateName", name: "Template", resizable: true },
      {
        key: "emailSendStatus",
        name: "Status",
        formatter(obj: any) {
          return <div> {obj?.row?.emailSendStatus ? "Success" : "Failed"}</div>;
        },
        width: 200,
        resizable: true,
      },
      {
        key: "createTimestamp",
        name: "Sent Date",
        formatter(obj: any) {
          const start = dateFormat(obj?.row?.createTimestamp)
          
          return <div> {start} </div>;
        },
        width: 200,
        resizable: true,
      },
    ];
  }, []);

  return (
    <EmailProvider {...useEmailPageMethods}>
      <Row style={{ marginBottom: "10px" }}>
        <AcPageList
          columns={columns}
          data={emailPageData?.content}
          pageInfo={emailPageData}
          setLimitPerPage={setLimitPerPage}
          setActivePage={setActivePage}
          activePage={activePage}
          selected={selectedCustomers}
          setSelected={setSelectedCustomers}
          setSortDirection={setSortDirection}
          limitPerPage={limitPerPage}
          pagination={true}
          whenNoData={
            <>
              <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
            </>
          }
          loading={isLoading}
        >
          <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
        </AcPageList>
      </Row>
    </EmailProvider>
  );
};

export default DashboaedEmail;
