import { useState, useEffect } from "react";
import { ApiService } from "../../apiServices";
import { createApiPageRequest } from "../../apiServices/common/domain/PageUtil";
export interface UseCustomerPageProps {
  businessCalendar?: any;
}
export type UseCustomerPageMethods = {
  setCustomerPageData: (data: any) => void;
  getCustomerPageData: () => void;
  customerPageData?: any;
  isLoading: boolean;
  setActivePage: (page: any) => void;
  activePage?: any;
  setLimitPerPage: (page: any) => void;
  limitPerPage?: any;
  setFilterObj: (key: any) => void;
  filterObj?: any;
  setFormObj: (obj: any) => void;
  formObj?: any;
  updateCustomer: (obj: any) => any;
  createNewCustomer: (obj: any) => any;
  updateGroup: (obj: any) => void;
  createNewGroup: (obj: any) => any;
  getAllGroups: () => any;
  getCustomerById: (obj: any) => any;
  getGroupById: (obj: any) => any;
  // groupPageData?: any;
  allGroups?: any;
  allCustomers?: any;
  deleteCustomers: (obj: any) => any;
  deleteGrpups: (obj: any) => any;
  exportCustomer: () => any;
  exportGroup: () => any;
  setCallPage?: (obj: boolean) => any;
};

const restApi: ApiService = ApiService.getInstance();

export const useCustomerPage = ({
  businessCalendar,
}: UseCustomerPageProps): UseCustomerPageMethods => {
  const [customerPageData, setCustomerPageData] = useState<any>([]);
  // const [groupPageData, setGroupPageData] = useState<any>([]);
  const [allCustomers, setAllCustomers] = useState<any>([]);
  const [allGroups, setAllGroups] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [callPage, setCallPage] = useState<boolean>(true);
  const [formObj, setFormObj] = useState<any>({});
  const [activePage, setActivePage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [filterObj, setFilterObj] = useState<any>("");

  const getHostUrl = () => {
    return process.env.REACT_APP_API_URL + "/api";
  };

  const getCustomerPageData = async () => {

    console.log('%cfilterObj:', 'color:yellow',filterObj );
    const qs = {
      page: activePage,
      size: limitPerPage,
      keyword: filterObj,
      // sorts: [["ARTIST_ID", "DESC"]],
    };
    const data = await restApi.customerService.findPage(createApiPageRequest(qs));
    setCustomerPageData(data.data.data);
  };

  const updateCustomer = async (fieldValue: any) => {
    const data = await restApi.customerService.updateCustomer(fieldValue);

    if (data?.data?.hasError === false) {
      getCustomerPageData();
    }
    return data;
  };

  const createNewCustomer = async (fieldValue: any) => {
    const data = await restApi.customerService.createCustomer(fieldValue);
    if (data?.data?.hasError === false) {
      getCustomerPageData();
      return data;
    }
    return data;
  };

  const getCustomerById = async (id: number) => {
    const data = await restApi.customerService.getCustomerById(id);
    return data;
  };
  const getGroupById = async (id: number) => {
    const data = await restApi.groupService.getGroupById(id);
    return data;
  };

  const getAllGroups = async () => {
    const data = await restApi.groupService.getAllGroups();
    // setGroupPageData(data.data);
    setAllGroups(data.data);
    setIsLoading(false);
    setAllGroups(data.data);
  };

  const getAllCustomers = async () => {
    const data = await restApi.customerService.getAllCustomers();
    setAllCustomers(data.data);
  };

  const updateGroup = async (fieldValue: any) => {
    const data = await restApi.groupService.update(fieldValue);
    getAllGroups();
    return true;
  };

  const createNewGroup = async (fieldValue: any) => {
    const data = await restApi.groupService.create(fieldValue);
    if (!data?.hasError) {
      getAllGroups();
      return true;
    }
    return false;
  };

  const deleteCustomers = async (selectedIds: any) => {
    const data = await restApi.customerService.deleteByIds(selectedIds);
    if (!data?.hasError) {
      getCustomerPageData();
      return true;
    }
    return false;
  };

  const deleteGrpups = async (selectedIds: any) => {
    const data = await restApi.groupService.deleteByIds(selectedIds);
    if (!data?.hasError) {
      getAllGroups();
      return true;
    }
    return false;
  };

  const exportCustomer = async () => {
    window.open(`${getHostUrl()}/export/customer`, "_blank");
  };

  const exportGroup = async () => {
    window.open(`${getHostUrl()}/export/group`, "_blank");
  };

  useEffect(() => {
    getCustomerPageData();
    setIsLoading(false);
    getAllGroups();
    getAllCustomers();
  }, [limitPerPage, activePage, callPage]);

  return {
    setCustomerPageData,
    getCustomerPageData,
    customerPageData,
    isLoading,
    setActivePage,
    activePage,
    setLimitPerPage,
    limitPerPage,
    filterObj, 
    setFilterObj,
    formObj,
    setFormObj,
    updateCustomer,
    createNewCustomer,
    updateGroup,
    createNewGroup,
    // groupPageData,
    allGroups,
    allCustomers,
    getAllGroups,
    getCustomerById,
    getGroupById,
    deleteCustomers,
    deleteGrpups,
    exportCustomer,
    exportGroup,
    setCallPage,
  };
};
