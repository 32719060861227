import React, { FC } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { Stack } from "rsuite";

export interface BrandProps {}

export const Brand: FC<BrandProps> = props => {
  return (
    <Stack style={{ width: "266px", height: "49px" }} className="brand" {...props}>
      <Logo style={{ marginTop: 6 }} width={22} height={26} />
      {/* <Link to="/">
        <span style={{ marginLeft: 14 }}>Emailer</span>
      </Link> */}
    </Stack>
  );
};
export default Brand;
