import React, { useRef } from "react";
import { Form, Button, Panel, Stack, Schema } from "rsuite";
import useAuth from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const { StringType } = Schema.Types;

const model = Schema.Model({
  username: StringType(), //.isRequired('Username is required.'),
  password: StringType(), // allows impersonate
});
const TextField = React.forwardRef((props: any, ref: any) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group className="form-group" controlId={`${name}-4`} ref={ref}>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

const ChangePassword = () => {
  const { changePassword, loading } = useAuth();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState<Record<string, any>>({
    oldPassword: "",
    newPassword: "",
  });

  async function handleSubmit() {
    if (Object.keys(formError).length !== 0) {
      return;
    }
    const isSignedIn = await changePassword(formValue.oldPassword, formValue.newPassword);
    if (isSignedIn) {
      navigate("/");
    }
  }

  return (
    <Stack
      ref={contentRef}
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Panel bordered style={{ background: "#fff", width: 400 }} header={<h3>Change Password</h3>}>
        <Form
          fluid
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}
          model={model}
        >
          <Form.Group>
            <Form.ControlLabel>Current Password</Form.ControlLabel>
            <TextField name="oldPassword" type="password" autoComplete="off" />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>
              <span>New Password</span>
            </Form.ControlLabel>
            <TextField name="newPassword" type="password" autoComplete="off" />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>
              <Button disabled={loading} appearance="primary" onClick={handleSubmit}>
                Change Password
              </Button>
            </Form.ControlLabel>
          </Form.Group>
        </Form>
      </Panel>
    </Stack>
  );
};

export default ChangePassword;
