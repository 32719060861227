import React from "react";
import image from "../assets/favicon.png";
interface LogoProps {
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
}

export default function Logo({ width, height, style, className = "" }: LogoProps) {
  const styles = { width, height, display: "inline-block", ...style };
  return (
    <div
      style={styles}
      className={`rsuite-logo logo-animated logo-animated-delay-half-seconds bounce-in ${className} `}
    >
      <img src={image} width="22px" height="25px" alt="" /> <span>Miolawfirm</span>
    </div>
  );
}
