/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { Form, Button, Panel, Stack, Schema, Input } from "rsuite";
import useAuth from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const { StringType } = Schema.Types;

const model = Schema.Model({
  username: StringType(), //.isRequired('Username is required.'),
  password: StringType(), // allows impersonate
});
const TextField = React.forwardRef((props: any, ref: any) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group className="form-group" controlId={`${name}-4`} ref={ref}>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

const Login = () => {
  const { login, sendOTP, loading, user } = useAuth();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);
  const [formError, setFormError] = React.useState({});
  const [errorCode, setErrorCode] = React.useState();
  const [formValue, setFormValue] = React.useState<Record<string, any>>({
    username: "",
    password: "",
  });
  const [otpValues, setOtpValues] = React.useState<any>();
  const [userInputOTP, setUserInputOTP] = React.useState();

  async function handleSubmit() {
    if (Object.keys(formError).length !== 0) {
      return;
    }
    const response = await login(formValue.username, formValue.password);

    if (!response?.errorMessage) {
      console.log("%c response:", "color:yellow", response?.data);
      setErrorCode(undefined);
      setOtpValues(response);
      // navigate("/");
    } else {
      setErrorCode(response?.errorMessage?.error?.code);
    }
  }

  async function handleOTPSubmit() {
    console.log("%c otp submit:", "color:yellow", userInputOTP, otpValues);

    const payload = {
      userId: otpValues?.data?.data?.userId,
      otpCode: userInputOTP,
    };
    const response = await sendOTP(payload);

    if (!response?.errorMessage) {
      console.log("%c response:", "color:yellow", response);
      navigate("/");
    } else {
      setErrorCode(response?.errorMessage?.error?.code);
    }
  }

  const upadteFieldValue = (value: any) => {
    setUserInputOTP(value);
  };

  return (
    <Stack
      ref={contentRef}
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Panel bordered style={{ background: "#fff", width: 400 }} header={<h3>Sign In</h3>}>
        <Form
          fluid
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}
          model={model}
        >
          {!otpValues && (
            <>
              <Form.Group>
                <Form.ControlLabel>Username or email address</Form.ControlLabel>
                <TextField name="username" />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>
                  <span>Password</span>
                  {/* <a style={{ float: "right" }}>Forgot password?</a> */}
                </Form.ControlLabel>
                <TextField name="password" type="password" autoComplete="off" />
              </Form.Group>
            </>
          )}

          {otpValues && (
            <Form.Group>
              <Form.ControlLabel>Enter OTP </Form.ControlLabel>
              <a style={{ float: "right" }}>OTP sent to your Email</a>
              <Input
                value={userInputOTP}
                onChange={(value: string | number | readonly string[], _event) =>
                  upadteFieldValue(value)
                }
              />
            </Form.Group>
          )}

          <Form.Group>
            {errorCode && <span style={{ color: "red" }}> Invalid username or password</span>}
            <Form.ControlLabel>
              {!otpValues && (
                <Button disabled={loading} appearance="primary" onClick={handleSubmit}>
                  Sign in
                </Button>
              )}
            </Form.ControlLabel>
          </Form.Group>
        </Form>
        {otpValues && (
          <Button appearance="primary" onClick={handleOTPSubmit}>
            Confirm OTP
          </Button>
        )}
      </Panel>
    </Stack>
  );
};

export default Login;
