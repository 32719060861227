import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Row, Col, Button, Grid, Input } from "rsuite";
import { ApiService } from "../../apiServices";
import { notifyMessage } from "../../components/acComponents/Notification/JToast";
import _ from "lodash";
import { useSmsContext } from "./SmsContext";
export interface Field {
  fieldName: string;
  type: string;
  label: string;
  errorId?: string;
  value?: any;
  validator?: (value: any) => string | undefined;
}
export const smsFormConfig: Field[] = [
  { fieldName: "templateName", type: "input", label: "Template Name" },
  { fieldName: "templateContent", type: "textarea", label: "Template" },
];

const initialSmsTemplateFormValue = {
  templateName: "",
  templateContent: "",
};

export interface EmailFormProps {
  formData: any;
}
export interface formMethods {
  saveOrUpdateSmsTemplate: (type: string) => any;
}

export const SmsForm = forwardRef<formMethods, EmailFormProps>((props, ref) => {
  const { formData } = props;
  const [fieldValue, setFieldValue] = useState<any>(formData?.obj?.row || {});
  const { updateSmsTemplate, createNewSmsTemplate } = useSmsContext();

  useEffect(() => {
    if (formData?.obj?.row?.id) {
      setFieldValue(formData?.obj?.row);
    } else {
      setFieldValue(initialSmsTemplateFormValue);
    }
  }, [formData]);

  useImperativeHandle(ref, () => ({
    async saveOrUpdateSmsTemplate(type: string) {
      if (type === "edit") {
        updateSmsTemplate(fieldValue);
        notifyMessage("The Template has been successfully updated", "SUCCESS");
        return true;
      } else {
        let flag = await createNewSmsTemplate(fieldValue);
        if (flag) {
          notifyMessage("The Template has been successfully created", "SUCCESS");
        } else {
          notifyMessage("Template has failed to update", "ERROR");
        }
        return true;
      }
    },
  }));

  const upadteFieldValue = (key: string, value: any) => {
    if (fieldValue?.hasOwnProperty(key)) {
      let obj: any = {};
      obj[key] = value;
      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    } else {
      let obj: any = {};
      obj[key] = value;

      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    }
  };

  const inputGroup = (obj: Field, _index: number) => {
    return (
      <Row key={_index} style={{ marginBottom: "10px" }}>
        <Col xs={6}> {obj.label}</Col>
        <Col xs={18}>
          {obj?.type === "input" && (
            <Input
              value={fieldValue?.[obj.fieldName] || ""}
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
            />
          )}
          {obj?.type === "textarea" && (
            <Input
              as="textarea"
              rows={3}
              placeholder="Textarea"
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
              defaultValue={fieldValue?.[obj.fieldName]}
            />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div className="sustomerForm">
      <Grid fluid>{smsFormConfig?.map((obj: any, index: any) => inputGroup(obj, index))}</Grid>
    </div>
  );
});

export default SmsForm;
