import React, { FC, PropsWithChildren } from "react";
import { UseSmsPageMethods } from "./UseSmsPage";

export const SmsContext = React.createContext<UseSmsPageMethods | null>(null);

export const useSmsContext = () => React.useContext(SmsContext) as UseSmsPageMethods;
export const SmsProvider: FC<UseSmsPageMethods> = ({
  children,
  ...props
}: PropsWithChildren<UseSmsPageMethods>) => {
  return <SmsContext.Provider value={{ ...props }}>{children}</SmsContext.Provider>;
};
