import { useState, useEffect } from "react";
import { ApiService } from "../../apiServices";
import { createApiPageRequest } from "../../apiServices/common/domain/PageUtil";

export interface UseSmsPageProps {
  businessCalendar?: any;
}
export type UseSmsPageMethods = {
  smsTemplatePageData?: any;
  isLoading: boolean;
  setActivePage: (page: any) => void;
  activePage?: any;
  setLimitPerPage: (page: any) => void;
  limitPerPage?: any;
  setFormObj: (obj: any) => void;
  formObj?: any;
  updateSmsTemplate: (obj: any) => any;
  createNewSmsTemplate: (obj: any) => any;
  deleteSmsTemplate: (obj: any) => any;
};

const restApi: ApiService = ApiService.getInstance();

export const useSmsPage = ({ businessCalendar }: UseSmsPageProps): UseSmsPageMethods => {
  const [smsTemplatePageData, setSmsTemplatePageData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formObj, setFormObj] = useState<any>({});
  const [activePage, setActivePage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);

  const getSmsTemplatePageData = async () => {
    // const qs = {
    //   page: activePage,
    //   size: limitPerPage,
    //   sorts: [["SMS_TEMPLATE_ID", "DESC"]],
    // };
    // const data = await restApi.smsService.findPage(createApiPageRequest(qs));
    //setSmsTemplatePageData(data.data.data);
    const data = await restApi.smsService.getAllSms();
    setSmsTemplatePageData(data.data);
  };

  const updateSmsTemplate = async (fieldValue: any) => {
    const data = await restApi.smsService.update(fieldValue);
    if (!data?.hasError) {
      getSmsTemplatePageData();
      return true;
    }
    return false;
  };

  const createNewSmsTemplate = async (fieldValue: any) => {
    const data = await restApi.smsService.create(fieldValue);
    if (!data?.hasError) {
      getSmsTemplatePageData();
      return true;
    }
    return false;
  };

  const deleteSmsTemplate = async (selectedIds: any) => {
    const data = await restApi.smsService.deleteByIds(selectedIds);
    if (!data?.hasError) {
      getSmsTemplatePageData();
      return true;
    }
    return false;
  };

  useEffect(() => {
    getSmsTemplatePageData();
  }, []);

  return {
    smsTemplatePageData,
    isLoading,
    setActivePage,
    activePage,
    setLimitPerPage,
    limitPerPage,
    formObj,
    setFormObj,
    updateSmsTemplate,
    createNewSmsTemplate,
    deleteSmsTemplate,
  };
};
