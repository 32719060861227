import React, { FC, PropsWithChildren } from "react";
import { UseCustomerPageMethods } from "./UseCustomerPage";

export const CustomerContext = React.createContext<UseCustomerPageMethods | null>(null);

export const useCustomerContext = () => React.useContext(CustomerContext) as UseCustomerPageMethods;
export const CustomerProvider: FC<UseCustomerPageMethods> = ({
  children,
  ...props
}: PropsWithChildren<UseCustomerPageMethods>) => {
  return <CustomerContext.Provider value={{ ...props }}>{children}</CustomerContext.Provider>;
};
