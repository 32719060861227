import { UrlUtil } from "../../util/UrlUtil";
import _ from "lodash";
const axios = require("axios");

export interface IHeader {
  Accept?: string;
  "Accept-Language"?: string;
  "Content-Type"?: string;
  Authorization?: string;
  "X-CSRF-TOKEN"?: string;
  Host?: string;
}

export class UserInputError {
  constructor(message: string, extensions?: Record<string, any>) {
    Object.defineProperty(this, "name", { value: "UserInputError" });
  }
}

export class RestAxiosService {
  createHeaders() {
    let headers: IHeader = {
      Accept: "*/*",
      "Content-Type": "application/json; charset=utf-8",
    };
    return headers;
  }

  getHostUrl() {
    return process.env.REACT_APP_API_URL + "/api";
  }

  async getRequest(
    baseURL: string,
    path: string,
    qs: Record<string, any> = {},
    headers: Record<string, any> = {}
  ) {
    let data = null;
    let loaded = false;
    let errorMessage = "";
    const _headers = _.merge(this.createHeaders(), headers);
    const config = {
      baseURL: this.getHostUrl(),
      headers: _headers,
      withCredentials: false,
    };

    // console.log("%cqsqs:", "color:yellow", qs);
    const requestUrl = this.getHostUrl() + UrlUtil.createPath(path, qs);
    await axios
      .get(requestUrl, config)
      .then((response: any) => (data = response.data))
      .catch((err: any) => {
        errorMessage = err?.response?.data || err?.message || err;
      })

      .finally(() => (loaded = true));
    return { data, errorMessage, loaded };
  }

  async postRequest(
    baseURL: string,
    path: string,
    body: any,
    headers: Record<string, any> = {},
    apiName: string
  ) {
    let data = null;
    let loaded = false;
    let errorMessage = "";
    const requestUrl = this.getHostUrl() + path;
    const _headers = _.merge(this.createHeaders(), headers);
    const config = {
      baseURL: this.getHostUrl(),
      headers: _headers,
    };
    await axios
      .post(requestUrl, JSON.stringify(body), config)
      .then((response: any) => {
        data = response.data;
      })
      .catch((err: any) => {
        // console.log("%cerror-err:", "color:yellow", err);
       let serverErrorPayload;
       if (err.error) {
         try {
           serverErrorPayload = JSON.parse(err.error);
         } catch (e) {}
       }

       if (err.statusCode === 400 && serverErrorPayload) {
         console.log("%cerror-serverErrorPayload:", "color:yellow", serverErrorPayload);
         if (
           serverErrorPayload.error &&
           serverErrorPayload.error.code === "INPUT_ERROR" &&
           serverErrorPayload.error.errors
         ) {
           // console.log("%cthrows:", "color:yellow");
           throw new UserInputError(apiName, serverErrorPayload.error.errors);
         }
         if (serverErrorPayload.error && serverErrorPayload.error.code === "BAD_CREDENTIALS") {
           console.log("%c get - BAD_CREDENTIALS:", "color:yellow");
         }
       }

       // return Promise.reject(
       //   new Error(`postRequest: ${apiName} network error. ${JSON.stringify(err)}`)
       // );

       // console.log("%c catch-response:", "color:yellow", err.response.data);

       // throw new UserInputError('test', err.response.data.error.errorsAsList || []);

       // console.log('%c catch-response:', 'color:yellow', err.response.data);
       errorMessage = err.response.data || err.message;
       // if (400 <= err.response.status && err.response.status < 499) {
       //   errorLevel = 'WARN';
       // }
       // if (500 <= err.response.status && err.response.status < 599) {
       //   errorLevel = 'ERROR';
       // }
      })
      .finally(() => (loaded = true));
    return { data, errorMessage, loaded };
  }

  async putRequest(
    baseURL: string,
    path: string,
    body: any,
    headers: Record<string, any> = {},
    apiName: string
  ) {
    let data = null;
    let loaded = false;
    let errorMessage = "";
    const requestUrl = this.getHostUrl() + path;
    const _headers = _.merge(this.createHeaders(), headers);
    const config = {
      baseURL: this.getHostUrl(),
      headers: _headers,
    };
    await axios
      .put(requestUrl, JSON.stringify(body), config)
      .then((response: any) => {
        data = response.data;
      })
      .catch((err: any) => {
       // console.log("%cerror-err:", "color:yellow", err);
       let serverErrorPayload;
       if (err.error) {
         try {
           serverErrorPayload = JSON.parse(err.error);
         } catch (e) {}
       }

       if (err.statusCode === 400 && serverErrorPayload) {
         console.log("%cerror-serverErrorPayload:", "color:yellow", serverErrorPayload);
         if (
           serverErrorPayload.error &&
           serverErrorPayload.error.code === "INPUT_ERROR" &&
           serverErrorPayload.error.errors
         ) {
           // console.log("%cthrows:", "color:yellow");
           throw new UserInputError(apiName, serverErrorPayload.error.errors);
         }
         if (serverErrorPayload.error && serverErrorPayload.error.code === "BAD_CREDENTIALS") {
           console.log("%c get - BAD_CREDENTIALS:", "color:yellow");
         }
       }

       // return Promise.reject(
       //   new Error(`postRequest: ${apiName} network error. ${JSON.stringify(err)}`)
       // );

       // console.log("%c catch-response:", "color:yellow", err.response.data);

       // throw new UserInputError('test', err.response.data.error.errorsAsList || []);

       // console.log('%c catch-response:', 'color:yellow', err.response.data);
       errorMessage = err.response.data || err.message;
       // if (400 <= err.response.status && err.response.status < 499) {
       //   errorLevel = 'WARN';
       // }
       // if (500 <= err.response.status && err.response.status < 599) {
       //   errorLevel = 'ERROR';
       // }
      })
      .finally(() => (loaded = true));
    return { data, errorMessage, loaded };
  }

  async deleteRequest(
    baseURL: string,
    path: string,
    body: any,
    headers: Record<string, any> = {},
    apiName: string
  ) {
    let data = null;
    let loaded = false;
    let errorMessage = "";
    const requestUrl = this.getHostUrl() + path;
    const _headers = _.merge(this.createHeaders(), headers);
    const config = {
      baseURL: this.getHostUrl(),
      headers: _headers,
    };
    await axios
      .delete(requestUrl, JSON.stringify(body), config)
      .then((response: any) => {
        data = response.data;
      })
      .catch((err: any) => {
        console.log("%c catch-error:", "color:yellow", err.response.data);
        let serverErrorPayload;
        if (err.error) {
          try {
            serverErrorPayload = JSON.parse(err.error);
          } catch (e) {}
        }

        if (err.statusCode === 400 && serverErrorPayload) {
          if (
            serverErrorPayload.error &&
            serverErrorPayload.error.code === "INPUT_ERROR" &&
            serverErrorPayload.error.errors
          ) {
            throw new UserInputError(apiName, serverErrorPayload.error.errors);
          }
          if (serverErrorPayload.error && serverErrorPayload.error.code === "BAD_CREDENTIALS") {
            console.log("%c get - BAD_CREDENTIALS:", "color:yellow");
          }
        }

        // return Promise.reject(
        //   new Error(`postRequest: ${apiName} network error. ${JSON.stringify(err)}`)
        // );
      })
      .finally(() => (loaded = true));
    return { data, errorMessage, loaded };
  }

  async fileUploadRequest(
    baseURL: string,
    path: string,
    headers: Record<string, any> = {},
    FormDataObj: FormData | undefined,
    onUploadProgress: any,
    body: any
  ) {
    const requestUrl = this.getHostUrl() + path;
    const _headers = _.merge(this.createHeaders(), headers);

    return await axios.post(requestUrl, FormDataObj, {
      baseURL: this.getHostUrl(),
      headers: _headers,
      onUploadProgress,
    });
  }
}
