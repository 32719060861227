import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Row, Nav, Col, ButtonGroup, Button, Grid, Placeholder, Input } from "rsuite";
import { notifyMessage } from "../../components/acComponents/Notification/JToast";
import _ from "lodash";
import { useEmailContext } from "./EmailContext";
// import { Editor } from "react-draft-wysiwyg";
// import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
// // import draftToHtmlPuri from "draftjs-to-html";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from 'draftjs-to-html';
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export interface Field {
  fieldName: string;
  type: string;
  label: string;
  errorId?: string;
  value?: any;
  validator?: (value: any) => string | undefined;
}
export const emailFormConfig: Field[] = [
  { fieldName: "templateName", type: "input", label: "Template Name" },
  { fieldName: "templateSubject", type: "input", label: "Subject" },
  { fieldName: "templateContent", type: "textarea", label: "Template" },
];

const initialEmailTemplateFormValue = {
  templateName: "",
  templateSubject: "",
  templateContent: "",
};

export interface EmailFormProps {
  formData: any;
}
export interface formMethods {
  saveOrUpdateEmailTemplate: (type: string) => any;
}

export const EmailForm = forwardRef<formMethods, EmailFormProps>((props, ref) => {
  const { formData } = props;
  const [fieldValue, setFieldValue] = useState<any>(formData?.obj?.row || {});

  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());

  // const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());
  const { updateEmailTemplate, createNewEmailTemplate } = useEmailContext();

  const loadContentFromApiResponse = (apiResponse: any): EditorState => {
    const htmlContent = apiResponse;
    const contentBlock = htmlToDraft(htmlContent);
    let editorState: EditorState;

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }

    return editorState;
  };

  useEffect(() => {
    if (formData?.obj?.row?.id) {
      setFieldValue(formData?.obj?.row);
      const loadedEditorState = loadContentFromApiResponse(formData?.obj?.row?.templateContent);
      setEditorState(loadedEditorState);
    } else {
      setFieldValue(initialEmailTemplateFormValue);
    }
  }, [formData]);

  useImperativeHandle(ref, () => ({
    async saveOrUpdateEmailTemplate(type: string) {
      if (type === "edit") {
        updateEmailTemplate(fieldValue);
        notifyMessage("success update", "SUCCESS");
        return true;
      } else {
        createNewEmailTemplate(fieldValue);
        notifyMessage("Create Success", "SUCCESS");
        return true;
      }
    },
  }));

  const upadteFieldValue = (key: string, value: any) => {
    if (fieldValue?.hasOwnProperty(key)) {
      let obj: any = {};
      obj[key] = value;
      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    } else {
      let obj: any = {};
      obj[key] = value;

      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    }
  };

  const onEditorStateChange = async (_editorState: EditorState) => {
    setEditorState(_editorState);
    const _htmldata = draftToHtml(convertToRaw(_editorState.getCurrentContent()));
   //  let htmlConverted = await convertImages(_htmldata); 
    upadteFieldValue("templateContent", _htmldata);
  };


  


const convertImages = async (htmlText: any) => {
  const regex =  /<img\s[^>]*?style\s*=\s*['\"]float([^'\"]*?)['\"][^>]*?>/g;

  let m;
  while ((m = regex.exec(htmlText)) !== null) {
      if (m.index === regex.lastIndex) regex.lastIndex++;
      let repl: string | null = null,type: string | string[] | null = null;
      m.forEach((match, groupIndex) => {
          if(groupIndex==0)repl = match;
          if(groupIndex==1)type = match;
          if(repl && type){
              if(type.includes('none')) htmlText = htmlText.replace(repl, `<div style="text-align: center;width: 100%;">`+repl+'</div>');
              else htmlText = htmlText.replace(repl, `<div style="text-align ${type}; width: 100%;">`+repl+'</div>');
              repl = null;
              type = null;
          }
      });
  }
  console.log('%cregex:', 'color:yellow',htmlText )
 return htmlText;
};

  const inputGroup = (obj: Field, _index: number) => {
    return (
      <Row key={_index} style={{ marginBottom: "10px" }}>
        <Col xs={6}> {obj.label}</Col>
        <Col xs={18}>
          {obj?.type === "input" && (
            <Input
              value={fieldValue?.[obj.fieldName] || ""}
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
            />
          )}
          {obj?.type === "textarea" && (
            <>
              {/* <Editor
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onContentStateChange={onEditorStateChange}
              /> */}
              {/* <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
              /> */}
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
              />
              {/* <textarea
                style={{ width: "200px", height: "300px" }}
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
              /> */}
            </>
            // <ReactQuill
            //   style={{ minHeight: "350px" }}
            //   value={fieldValue?.[obj.fieldName]}
            //   modules={quillModules}
            //   formats={quillFormats}
            //   onChange={onEditorStateChange}
            // />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div className="sustomerForm">
      <Grid fluid style={{ minHeight: "600px" }}>
        {emailFormConfig?.map((obj: any, index: any) => inputGroup(obj, index))}
      </Grid>
    </div>
  );
});

export default EmailForm;
