import { ApiRestService } from "../../common/ApiRestService";
import { PageRequest } from "../../common/domain/PageRequest";
import AuthStorage from "../../../context/AuthStorage";
export class EmailTemplateService extends ApiRestService {
  toHeaders() {
    let headers: Record<string, any> = {};
    headers["Authorization"] = AuthStorage.getAuthorization();
    return headers;
  }

  async findPage(pageRequest: PageRequest = new PageRequest()) {
    const pageReq: PageRequest = pageRequest;
    if (pageRequest.sorts && pageRequest.sorts.length > 0) {
      const sort = pageRequest.sorts[0];
      const sortField = sort[0];
      let sortDirection = "ASC";
      if (sort.length > 1) {
        sortDirection = sort[1].toUpperCase();
      }
      if (!pageReq.filter) pageReq.filter = {};
      pageReq.filter.sortItem = `${sortField}_${sortDirection}`;
    }
    return this.getPage({
      apiName: "sms-page",
      baseURL: "http://localhost:8070/api",
      path: `/email/template`,
      pageRequest: pageReq,
      headers: this.toHeaders(),
    });
  }

  //http://localhost:8070/api/sms/template?page=1&pagingSize=SIZE_10&sortItem=SMS_TEMPLATE_ID_DESC

  async getAll() {
    return this.getPage({
      apiName: "SMS-All",
      baseURL: "http://localhost:8070/api",
      path: `/email/template/all`,
      headers: this.toHeaders(),
    });
  }

  async getById(id: number) {
    return this.getPage({
      apiName: "sms-byId",
      baseURL: "http://localhost:8070/api",
      path: `/email/template/${id}`,
      headers: this.toHeaders(),
    });
  }

  async create(obj: any) {
    return this.post({
      apiName: "sms-page-create",
      baseURL: "http://localhost:8070/api",
      path: `/email/template`,
      body: obj,
      headers: this.toHeaders(),
    });
  }

  async sendSms(obj: any) {
    return this.post({
      apiName: "sms-page-create",
      baseURL: "http://localhost:8070/api",
      path: `/templates/email/send`,
      body: obj,
      headers: this.toHeaders(),
    });
  }

  async update(obj: any) {
    const _body = obj;
    return this.put({
      apiName: "sms-page-update",
      baseURL: "http://localhost:8070/api",
      path: `/email/template/${_body?.id}`,
      body: obj,
      headers: this.toHeaders(),
    });
  }

  async deleteByIds(selectedIds: any) {
    const _body = {
      ids: selectedIds,
    };
    return this.post({
      apiName: "sms-page-update",
      baseURL: "http://localhost:8070/api",
      path: `/email/template/delete-selected`,
      body: _body,
      headers: this.toHeaders(),
    });
  }
}
