import React, { ReactNode } from "react";
import { HashRouter, Routes, Route, RouteProps, Navigate } from "react-router-dom";
// import loadable from "@loadable/component";
import { DefaultLayout } from "./components/layout";
import { SentItems, Dashboard } from "./pages/dashboard";
import Login from "./pages/login";
import ChangePassword from "./pages/changepassword";
import ContactPage from "./pages/contact";
import EmailPage from "./pages/email";
import FilemanagerPage from "./pages/filemanager";
import SmsPage from "./pages/sms";
import Error404Page from "./pages/authentication/404";
import useAuth from "./context/AuthContext";

type AsyncRouteProps = RouteProps & { importPath: () => Promise<any> };

// function AsyncRoute({ importPath, ...props }: AsyncRouteProps) {

//   return <Route {...props} element={loadable(importPath)} />;
// }

// const AsyncRoute = ({ importPath, ...props }: AsyncRouteProps) => {
//   return <Route {...props} element={loadable(importPath)} />;
// };

// function AuthenticatedRoute(props: AsyncRouteProps) {
//   const { user } = useAuth();

//   //if (!user) return <Redirect to="/login" />;

//   return <AsyncRoute {...props} />;
// }

const Router = () => {
  const { user, loading, canView } = useAuth();
  const PrivateRoute = () => {
    return !loading && user ? <DefaultLayout /> : <Navigate to="/login" />;
  };

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={canView("dashboard") && <Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sent" element={<SentItems />} />
          <Route path="/sent/:path" element={<SentItems />} />
          <Route path="/contact/" element={<ContactPage />} />
          <Route path="/contact/:path" element={<ContactPage />} />
          <Route path="/email" element={<EmailPage />} />
          <Route path="/filemanager" element={<FilemanagerPage />} />
          <Route path="/sms" element={<SmsPage />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/*" element={<Error404Page />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </HashRouter>
  );
};

export default Router;
