import React, { FC, useState, useMemo, useRef } from "react";
import { Row, Col } from "rsuite";
import { SelectColumn } from "react-data-grid";
import type { Column } from "react-data-grid";
import AcPageList from "../../components/acComponents/pageList";
import { SmsProvider } from "./SmsContext";
import { useSmsPage } from "./UseSmsPage";
import moment from "moment-timezone";

export interface DashboaedSmsProps {}
export const DashboaedSms: FC<DashboaedSmsProps> = props => {
  const useSmsPageMethods = useSmsPage({ businessCalendar: "test" });
  const { isLoading, smsPageData, limitPerPage, setLimitPerPage, activePage, setActivePage } =
    useSmsPageMethods;

  const [selectedCustomers, setSelectedCustomers] = useState<Set<number>>(() => new Set());
  const [sortDirection, setSortDirection] = useState<any>();


  const dateFormat = (date: any, pattern = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(date).format(pattern);
  };


  console.log(dateFormat(new Date()));

  const columns = useMemo((): Column<any>[] => {
    return [
      { key: "id", name: "Id", width: 35 },
      { key: "customerName", name: "Customer Name", width: 180, resizable: true },
      { key: "customerPhone", name: "Phone", width: 180, resizable: true },
      { key: "groupName", name: "Group Name", width: 200, resizable: true },
      { key: "templateName", name: "Template", resizable: true },
      {
        key: "smsSendStatus",
        name: "Status",
        formatter(obj: any) {
          return <div> {obj?.row?.smsSendStatus ? "Success" : "Failed"}</div>;
        },
        width: 200,
        resizable: true,
      },
      {
        key: "createTimestamp",
        name: "Sent Date",
        formatter(obj: any) {
          const start = dateFormat(obj?.row?.createTimestamp)
          
          return <div> {start} </div>;
        },
        width: 200,
        resizable: true,
      },
    ];
  }, []);

  return (
    <SmsProvider {...useSmsPageMethods}>
      <Row style={{ marginBottom: "10px" }}>
        <AcPageList
          columns={columns}
          data={smsPageData?.content}
          pageInfo={smsPageData}
          setLimitPerPage={setLimitPerPage}
          setActivePage={setActivePage}
          activePage={activePage}
          selected={selectedCustomers}
          setSelected={setSelectedCustomers}
          setSortDirection={setSortDirection}
          limitPerPage={limitPerPage}
          pagination={true}
          whenNoData={
            <>
              <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
            </>
          }
          loading={isLoading}
        >
          <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
        </AcPageList>
      </Row>
    </SmsProvider>
  );
};

export default DashboaedSms;
