import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Row, Col, Button, Grid, Input } from "rsuite";
import { ApiService } from "../../apiServices";
import { notifyMessage } from "../../components/acComponents/Notification/JToast";
import _ from "lodash";
import { useFileContext } from "./FileContext";
// const axios = require("axios");
import axios from "axios";
export interface Field {
  fieldName: string;
  type: string;
  label: string;
  errorId?: string;
  value?: any;
  validator?: (value: any) => string | undefined;
}
export const smsFormConfig: Field[] = [
  { fieldName: "fileName", type: "input", label: "File Name" },
  { fieldName: "fileDescription", type: "input", label: "Description" },
];

const initialSmsTemplateFormValue = {
  fileName: "",
  fileDescription: "",
};

export interface EmailFormProps {
  formData: any;
}
export interface formMethods {
  saveFiles: (type: string) => any;
}

const restApi: ApiService = ApiService.getInstance();

export const FileUploadForm = forwardRef<formMethods, EmailFormProps>((props, ref) => {
  const { formData } = props;
  const [currentFile, setCurrentFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const [fieldValue, setFieldValue] = useState<any>(formData?.obj?.row || {});
  const { fileInfoUpload } = useFileContext();

  useEffect(() => {
    if (formData?.obj?.row?.id) {
      setFieldValue(formData?.obj?.row);
    } else {
      setFieldValue(initialSmsTemplateFormValue);
    }
  }, [formData]);

  useImperativeHandle(ref, () => ({
    async saveFiles(type: string) {
      handleFileUpload();
      return true;
    },
  }));

  const handleFileUpload = async () => {
    setProgress(0);
    if (!currentFile) return;
    const formDataObj = new FormData();
    formDataObj.append("file", currentFile);
    formDataObj.append("fileName", fieldValue?.fileName);
    formDataObj.append("fileDescription", fieldValue?.fileDescription);
    restApi.fileService
      .fileInfoUpload(formDataObj, (event: any) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then(response => {
        notifyMessage("The file successfully uploaded", "SUCCESS");
        fileInfoUpload();
      })
      .catch(err => {
        setProgress(0);
        if (err?.response && err?.response?.data && err?.response?.data?.message) {
          notifyMessage(err?.response?.data?.message, "ERROR");
        } else {
          notifyMessage("Could not upload the File!", "ERROR");
        }
        setCurrentFile(undefined);
      });
  };

  const upadteFieldValue = (key: string, value: any) => {
    if (fieldValue?.hasOwnProperty(key)) {
      let obj: any = {};
      obj[key] = value;
      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    } else {
      let obj: any = {};
      obj[key] = value;

      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    }
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const { files } = event.target;
      const selectedFile = files as FileList;
      //setCurrentFile(selectedFile?.[0]);
      setCurrentFile(event.target.files[0]);
      setProgress(0);
    }
  };

  const inputGroup = (obj: Field, _index: number) => {
    return (
      <Row key={_index} style={{ marginBottom: "10px" }}>
        <Col xs={6}> {obj.label}</Col>
        <Col xs={18}>
          {obj?.type === "input" && (
            <Input
              value={fieldValue?.[obj.fieldName] || ""}
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
            />
          )}
          {obj?.type === "textarea" && (
            <Input
              as="textarea"
              rows={3}
              placeholder="Textarea"
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
              defaultValue={fieldValue?.[obj.fieldName]}
            />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div className="sustomerForm">
      <Grid fluid>
        {smsFormConfig?.map((obj: any, index: any) => inputGroup(obj, index))}
        <Row style={{ marginBottom: "10px" }}>
          <Col xs={6}> Choose Image</Col>
          <Col xs={18}>
            <input
              type="file"
              // accept=""
              onChange={selectFile}
            />
          </Col>
          <Row style={{ textAlign: "center", display: "flex" }}>
            <Col xs={24} style={{ height: "28px", marginTop: "3px", marginBottom: "0px" }}>
              {currentFile && (
                <div className="progress my-3">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: progress + "%" }}
                  >
                    {progress}%
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Row>
      </Grid>
    </div>
  );
});

export default FileUploadForm;
