import { FC } from 'react';

export const PageBody: FC<any> = props => {
  const { children } = props;
  return (
    <div className="page-content">
      <div id="content-main">{children}</div>
    </div>
  );
};

PageBody.displayName = 'PageBody';
