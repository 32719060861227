import { useState, useEffect } from "react";
import { ApiService } from "../../apiServices";
import { createApiPageRequest } from "../../apiServices/common/domain/PageUtil";
export interface UseSmsPageProps {
  businessCalendar?: any;
}
export type UseSmsPageMethods = {
  setSmsPageData: (data: any) => void;
  getCustomerPageData: () => void;
  smsPageData?: any;
  isLoading: boolean;
  setActivePage: (page: any) => void;
  activePage?: any;
  setLimitPerPage: (page: any) => void;
  limitPerPage?: any;
};

const restApi: ApiService = ApiService.getInstance();

export const useSmsPage = ({ businessCalendar }: UseSmsPageProps): UseSmsPageMethods => {
  const [smsPageData, setSmsPageData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);

  const getCustomerPageData = async () => {
    const qs = {
      page: activePage,
      size: limitPerPage,
      status: "all",
      sourceType: "all",
      sorts: [["SMS_SENDER_ID", "DESC"]],
    };
    const data = await restApi.dashBoardService.findSmsPage(createApiPageRequest(qs));
    //console.log("%chhh:", "color:yellow", data?.errorMessage);
    setSmsPageData(data?.data?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomerPageData();
    // async function fetchGroupById() {
    //   getCustomerPageData();
    //   setIsLoading(false);
    // }
    // fetchGroupById();
  }, [limitPerPage, activePage]);

  return {
    smsPageData,
    setSmsPageData,
    getCustomerPageData,
    isLoading,
    setActivePage,
    activePage,
    setLimitPerPage,
    limitPerPage,
  };
};
