import React, { FC, useState, useMemo, useRef, useEffect } from "react";
import { Row, Col, Grid, Input, SelectPicker, Button } from "rsuite";
import { notifyMessage } from "../../components/acComponents/Notification/JToast";
import _ from "lodash";
import { ApiService } from "../../apiServices";

const restApi: ApiService = ApiService.getInstance();

export interface EmailComposeModalProps {
  onModalClose:any
  selectedCustomerIds?: any;
  selectedGroupIds?: any;
}

const initialSmsSendFormValue = {
  templateId: "",
  selectedGroups: [],
  selectedCustomers: [],
};

export interface Field {
  fieldName: string;
  type: string;
  label: string;
  errorId?: string;
  value?: any;
  validator?: (value: any) => string | undefined;
}

export const SmsSendConfig: Field[] = [
  { fieldName: "templateId", type: "SelectPicker", label: "Select Template" },
];

export const EmailComposeModal: FC<EmailComposeModalProps> = props => {
  const { selectedCustomerIds, selectedGroupIds, onModalClose } = props;
  const [templateList, setTemplateList] = useState<any>([]);

  useEffect(() => {
    async function fetchSmsTemplate() {
      let response = await restApi.emailTemplateService.getAll();
      setTemplateList(
        response?.data.map((item: any) => ({ label: item?.templateName, value: item?.id }))
      );
    }
    fetchSmsTemplate();
  }, []);

  const [fieldValue, setFieldValue] = useState<any>();
  const [clientType, setClientType] = useState<any>();

  useEffect(() => {
    if (selectedCustomerIds?.length) {
      setClientType("customer");
      setFieldValue({
        sourceType: "customer",
        templateId: null,
        selectedCustomers: selectedCustomerIds,
        selectedGroups: [],
      });
    }
    if (selectedGroupIds?.length) {
      setClientType("group");
      setFieldValue({
        sourceType: "group",
        templateId: null,
        selectedGroups: selectedGroupIds,
        selectedCustomers: [],
      });
    }
  }, [selectedCustomerIds, selectedGroupIds]);

  const upadteFieldValue = (key: string, value: any) => {
    if (fieldValue?.hasOwnProperty(key)) {
      let obj: any = {};
      obj[key] = value;
      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    } else {
      let obj: any = {};
      obj[key] = value;
      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    }
  };

  const inputGroup = (obj: Field, _index: number) => {
    return (
      <Row key={_index} style={{ marginBottom: "10px" }}>
        <Col xs={6}> {obj.label}</Col>
        <Col xs={18}>
          {obj?.type === "input" && (
            <Input
              value={fieldValue?.[obj.fieldName] || ""}
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
            />
          )}
          {obj?.type === "SelectPicker" && templateList?.length && (
            <SelectPicker
              onChange={(value: any | null, event: React.SyntheticEvent) =>
                upadteFieldValue(obj.fieldName, value)
              }
              data={templateList}
              style={{ width: 300 }}
            />
          )}
        </Col>
      </Row>
    );
  };

  const handlSendSms = async () => {
    try {
      let response = await restApi.emailTemplateService.sendSms(fieldValue);
      console.log('%cresponse-email:', 'color:yellow',response )
      if(response?.errorMessage?.hasError){
        notifyMessage(response?.errorMessage?.error?.message, "ERROR");
        onModalClose()
      }else{
        notifyMessage(response.data.data.messageStatus, "SUCCESS");
        onModalClose()
      }
    } catch (e) {}
  };

  if (fieldValue === undefined) {
    return <div> Loading</div>;
  }

  return (
    <div className="sustomerForm">
      <Grid fluid>{SmsSendConfig?.map((obj: any, index: any) => inputGroup(obj, index))}</Grid>
      <Row style={{ marginBottom: "10px" }}>
        <Col xs={6}></Col>
        <Col xs={18}>
          <Button disabled={!fieldValue?.templateId} appearance="primary" onClick={handlSendSms}>
            Send Email
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default EmailComposeModal;
