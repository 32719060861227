import { useState, useEffect } from "react";
import { ApiService } from "../../apiServices";
import { createApiPageRequest } from "../../apiServices/common/domain/PageUtil";
export interface UseSmsPageProps {
  businessCalendar?: any;
}
export type UseEmailPageMethods = {
  setEmailPageData: (data: any) => void;
  emailPageData?: any;
  isLoading: boolean;
  setActivePage: (page: any) => void;
  activePage?: any;
  setLimitPerPage: (page: any) => void;
  getDashboardCount: () => void;
  dashboardData?: any;
  limitPerPage?: any;
};

const restApi: ApiService = ApiService.getInstance();

export const useEmailPage = ({ businessCalendar }: UseSmsPageProps): UseEmailPageMethods => {
  const [emailPageData, setEmailPageData] = useState<any>([]);
  const [dashboardData, setDashboardData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);

  const getCustomerPageData = async () => {
    const qs = {
      page: activePage,
      size: limitPerPage,
      status: "all",
      sourceType: "all",
      sorts: [["EMAIL_SENDER_ID", "DESC"]],
    };
    const data = await restApi.dashBoardService.findEmailPage(createApiPageRequest(qs));
    // console.log("%chhh:", "color:yellow", data?.errorMessage);
    setEmailPageData(data?.data?.data);
    setIsLoading(false);
  };

  const getDashboardCount = async () => {
    const dashObj = {
      countType: "all",
      startDate: "2023-06-04",
      endDate: "2023-06-08",
      status: "all",
      dateFilter: false,
    };

    const data = await restApi.dashBoardService.getDashBoard(dashObj);
    console.log("%c data:", "color:yellow", data);
    setDashboardData(data?.data?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomerPageData();
    // async function fetchGroupById() {
    //   getCustomerPageData();
    //   setIsLoading(false);
    // }
    // fetchGroupById();
  }, [limitPerPage, activePage]);

  return {
    emailPageData,
    setEmailPageData,
    dashboardData,
    isLoading,
    setActivePage,
    activePage,
    setLimitPerPage,
    limitPerPage,
    getDashboardCount,
  };
};
