import { useState, useEffect } from "react";
import { ApiService } from "../../apiServices";
import { createApiPageRequest } from "../../apiServices/common/domain/PageUtil";

export interface UseEmailPageProps {
  businessCalendar?: any;
}
export type UseEmailPageMethods = {
  setEmailTemplateData: (data: any) => void;
  emailTemplateData?: any;
  isLoading: boolean;
  setActivePage: (page: any) => void;
  activePage?: any;
  setLimitPerPage: (page: any) => void;
  limitPerPage?: any;
  setFormObj: (obj: any) => void;
  formObj?: any;
  updateEmailTemplate: (obj: any) => any;
  createNewEmailTemplate: (obj: any) => any;
  deleteEmailTemplate: (obj: any) => any;
};

const restApi: ApiService = ApiService.getInstance();

export const useEmailPage = ({ businessCalendar }: UseEmailPageProps): UseEmailPageMethods => {
  const [emailTemplateData, setEmailTemplateData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formObj, setFormObj] = useState<any>({});
  const [activePage, setActivePage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);

  const getEmailTemplatePageData = async () => {
    const qs = {
      page: activePage,
      size: limitPerPage,
      sorts: [["SMS_TEMPLATE_ID", "DESC"]],
    };
    const data = await restApi.emailTemplateService.findPage(createApiPageRequest(qs));
    setEmailTemplateData(data.data.data);
  };

  const updateEmailTemplate = async (fieldValue: any) => {
    const data = await restApi.emailTemplateService.update(fieldValue);
    if (!data?.hasError) {
      getAll();
      return true;
    }
    return false;
  };

  const getAll = async () => {
    const data = await restApi.emailTemplateService.getAll();
    setEmailTemplateData(data.data);
    setIsLoading(false);
  };

  const createNewEmailTemplate = async (fieldValue: any) => {
    const data = await restApi.emailTemplateService.create(fieldValue);
    if (!data?.hasError) {
      getAll();
      return true;
    }
    return false;
  };

  const deleteEmailTemplate = async (selectedIds: any) => {
    const data = await restApi.emailTemplateService.deleteByIds(selectedIds);
    if (!data?.hasError) {
      getAll();
      return true;
    }
    return false;
  };

  useEffect(() => {
    //getEmailTemplatePageData();
    getAll();
  }, [limitPerPage, activePage]);

  return {
    setEmailTemplateData,
    emailTemplateData,
    isLoading,
    setActivePage,
    activePage,
    setLimitPerPage,
    limitPerPage,
    formObj,
    setFormObj,
    updateEmailTemplate,
    createNewEmailTemplate,
    deleteEmailTemplate,
  };
};
