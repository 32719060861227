import React, { FC, useState } from "react";
import { Row, Nav, Col, SelectPicker, Button, Pagination } from "rsuite";
import type { Column, RowsChangeData, DataGridHandle } from "react-data-grid";
import SearchIcon from "@rsuite/icons/Search";
import { ValueType } from "rsuite/esm/Checkbox";
import { AcReactDataGrid } from "../table/AcReactDataGrid";
import AcLoading from "../loading";
import { AcPagination } from "../pagination/AcPagination";
import FilterBar from "../filterBar/FilterBar";

const PAGE_LIMIT = [
  { label: "10 / page ", value: 10 },
  { label: "30 / page ", value: 30 },
  { label: "50 / page", value: 50 },
  { label: "100 / page", value: 100 },
];

export interface AcPageListProps {
  children: any;
  columns: any;
  data: any;
  pageInfo?: any;
  setSelected: (selected: any) => void;
  setFilterObj?: (obj: any) => void;
  filterObj?:any;
  selected: any;
  setSortDirection: (selected: any) => void;
  setFormValue?: (data: any) => void;
  setLimitPerPage: (page: any) => void;
  setActivePage?: (page: any) => void;
  setCallPage?: (page: any) => void;
  activePage?: any;
  pagination: boolean;
  limitPerPage: number;
  whenNoData?: any;
  loading?: boolean;
  filter?: boolean;
}

const AcPageList: FC<AcPageListProps> = props => {
  const {
    columns,
    data,
    pageInfo,
    setSelected,
    setFilterObj,
    filterObj,
    selected,
    setFormValue,
    setSortDirection,
    setLimitPerPage,
    setActivePage,
    setCallPage,
    activePage,
    pagination,
    limitPerPage,
    filter = false,
    whenNoData = (
      <>
        <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
      </>
    ),
    loading,
  } = props;

  const onClickNew = (type: string) => {
    setFormValue?.({ type: "new", formObj: undefined });
  };

  const onDoubleClick = (selectedObj: any) => {
    setFormValue?.({ type: "edit", obj: selectedObj });
  };

  const onClickPage = (pageNumber: any) => {
    //console.log("%c pageNumber:", "color:yellow", pageNumber);
  };

  const start = (pageInfo?.pageNumber - 1) * pageInfo?.pageSize + 1;
  let end = start + pageInfo?.pageSize - 1;

  if (pageInfo?.totalCount < end) {
    end = pageInfo?.totalCount;
  }

  return (
    <>
      {pagination && data && (
        <Row style={{ textAlign: "center", display: "flex" }}>
          <Col xs={24} style={{ marginTop: "3px", marginBottom: "3px" }}>
            <div style={{ float: "left" }}>
              {`${start}-${end}`} of, {pageInfo?.totalCount}
            </div>
            <div style={{ float: "right" }}>
              <SelectPicker
                size="xs"
                data={PAGE_LIMIT}
                defaultValue={PAGE_LIMIT[0].value}
                cleanable={false}
                searchable={false}
                onChange={(pageLimit: ValueType | null, event) => setLimitPerPage(pageLimit)}
              />
               {/* <Button
                style={{
                  float: 'right',
                  border: '1px solid #ccc',
                  marginLeft: '15px',
                  padding: '0px 4px',
                }}
                size="xs"
              >
                <i className="fas fa-sliders icon-text"></i>
                <span className="icon-text">Filter</span>
              </Button>   */}
              { filter &&  <FilterBar filterObj={filterObj} setFilterObj={setFilterObj} setCallPage={setCallPage} /> }
                
            </div>
          </Col>
        </Row>
      )}
      <div id="ac-page-grid">
        {loading && (
          <div className="ac-page-grid-loader">
            <div className="loader-center">
              <AcLoading />
            </div>
          </div>
        )}
        {data && (
          <AcReactDataGrid
            columns={columns}
            rows={data}
            selectedRows={selected}
            callbackSortDirection={setSortDirection}
            onDoubleClick={onDoubleClick}
            whenNoData={whenNoData}
            setSelected={setSelected}
          />
        )}
      </div>

      {!loading && data && data.length !== 0 && pagination && (
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <AcPagination
            limitPerPage={limitPerPage}
            totalElements={pageInfo?.totalCount}
            activePage={activePage}
            setActivePage={setActivePage}
            onClickPage={onClickPage}
          />
        </div>
      )}
    </>
  );
};

export default AcPageList;
