import { useState, FC, useEffect } from "react";
import { Row, Col, Panel } from "rsuite";
// import * as images from '../../images/charts';
import { PageContainer, PageHeader, PageBody } from "../../components/layout";
import { BarChart, YAxis, Bars } from "@rsuite/charts";
import { LineChart } from "@rsuite/charts";
import { EmailProvider } from "./EmailContext";
import { useEmailPage } from "./UseEmailPage";

// import data from "./stacked.json";
const data: [string, ...number[]][] = [
  ["00:00", 650],
  ["01:00", 700],
];

const data2: [string, ...number[]][] = [
  ["2023-1", 650],
  ["2023-2", 200],
  ["2023-3", 650],
  ["2023-4", 750],
  ["2023-1-3", 650],
  ["2023-1-3", 650],
  ["2023-1-3", 650],
  ["2023-1-3", 400],
];
const data3: [string, ...number[]][] = [
  ["2023-1-3", 150],
  ["2023-1-3", 100],
  ["2023-1-3", 650],
  ["2023-1-3", 750],
  ["2023-1-3", 650],
  ["2023-1-3", 650],
  ["2023-1-3", 650],
  ["2023-1-3", 450],
];


export const Dashboard: FC<any> = props => {
  const useEmailPageMethods = useEmailPage({ businessCalendar: "test" });
  const { getDashboardCount, dashboardData } = useEmailPageMethods;

  useEffect(() => {
    getDashboardCount();
  }, []);

  useEffect(() => {
    if (dashboardData) {
      console.log("%cdashboardData:", "color:yellow", dashboardData);
    }
  }, [dashboardData]);

  const pageHeader = (
    <Row className="show-grid" style={{ height: "inherit" }}>
      <Col xs={6}>
        <h3 className="title"> Dashboard </h3>
      </Col>
    </Row>
  );

  return (
    <EmailProvider {...useEmailPageMethods}>
      <PageContainer>
        <PageHeader>{pageHeader}</PageHeader>
        <PageBody>
          <Row gutter={30} className="dashboard-header">
            <Col xs={6}>
              <Panel className="trend-box bg-gradient-red" style={{color:"white"}}>
                {/* <img className="chart-img" src={images.PVIcon} /> */}
                <div className="title" style={{fontWeight:"bold", fontSize:"17px", color:"black"}}>Customers </div>
                <div className="value">Total: {dashboardData?.responseInfo?.customer?.totalCount}</div>
                <div className="value">Deleted:{dashboardData?.responseInfo?.customer?.inActiveCount}</div>
                <div className="value">Active:{dashboardData?.responseInfo?.customer?.activeCount}</div>
              </Panel>
            </Col>
            <Col xs={6}>
              <Panel className="trend-box bg-gradient-blue" style={{color:"white"}}>
                {/* <img className="chart-img" src={images.VVICon} /> */}
                <div className="title" style={{fontWeight:"bold", fontSize:"17px", color:"black"}}>Groups </div>
                <div className="value">Total: {dashboardData?.responseInfo?.group?.totalCount}</div>
                <div className="value">Deleted:{dashboardData?.responseInfo?.group?.inActiveCount}</div>
                <div className="value">Active:{dashboardData?.responseInfo?.group?.activeCount}</div>
              </Panel>
            </Col>
            <Col xs={6}>
              <Panel className="trend-box bg-gradient-green" style={{color:"white"}}>
                {/* <img className="chart-img" src={images.UVIcon} /> */}
                <div className="title" style={{fontWeight:"bold", fontSize:"17px", color:"black"}} >Emails</div>
                <div className="value">Total:{dashboardData?.responseInfo?.emailSend?.totalCount}</div>
                <div className="value">Sent: {dashboardData?.responseInfo?.emailSend?.sendCount}</div>
                <div className="value">Failed: {dashboardData?.responseInfo?.emailSend?.notSendCount}</div>
               
              </Panel>
            </Col>
            <Col xs={6}>
              <Panel className="trend-box bg-gradient-blue" style={{color:"white"}}>
                {/* <img className="chart-img" src={images.UVIcon} /> */}
                <div className="title" style={{fontWeight:"bold", fontSize:"17px", color:"black"}}>Sms</div>
                <div className="value">Total: {dashboardData?.responseInfo?.smsSend?.totalCount}</div>
                <div className="value">Sent:{dashboardData?.responseInfo?.smsSend?.sendCount}</div>
                <div className="value">Failed:{dashboardData?.responseInfo?.smsSend?.notSendCount}</div>
              </Panel>
            </Col>
          </Row>

        
          {/* <BarChart data={data2}>
            <YAxis />
            <Bars name="sms" color="#2485C1" stack="sms" />
            
          </BarChart>
          <BarChart data={data3}>
            <YAxis />
            <Bars name="Email" color="#2485C1" stack="Email" />
            
          </BarChart> */}
        </PageBody>
      </PageContainer>
    </EmailProvider>
  );
};

Dashboard.displayName = "Dashboard";
