import { FC, useState } from "react";
import { NavLink as BaseNavLink, useParams } from "react-router-dom";
import { Row, Nav, Col, Button } from "rsuite";
import { PageContainer, PageHeader, PageBody } from "../../components/layout";
import { Customer } from "./Customer";
import { ContactGroup } from "./ContactGroup";
import { CustomerProvider } from "./CustomerContext";
import { useCustomerPage } from "./UseCustomerPage";

export interface ContactPageProps {}

export const ContactPage: FC<ContactPageProps> = props => {
  const useCustomerPageMethods = useCustomerPage({ businessCalendar: "test" });
  const routeParams = useParams();
  const [activeTab, setActiveTab] = useState(routeParams.path || "people");
  const pageHeader = (
    <Row className="show-grid" style={{ height: "inherit" }}>
      {/* <Col xs={2}>
        <h3 className="title"> Contact </h3>
      </Col> */}
      <Col xs={22}>
        <Nav
          appearance="subtle"
          activeKey={activeTab}
          onSelect={setActiveTab}
          style={{ marginTop: 4 }}
        >
          <Nav.Item as={BaseNavLink} to="/contact/people" eventKey="people">
            <i className="fas fa-users"></i> Contacts
          </Nav.Item>
          <Nav.Item as={BaseNavLink} to="/contact/group" eventKey="group">
            <i className="fas fa-layer-group"></i> Group
          </Nav.Item>
        </Nav>
      </Col>
      <Col xs={2} style={{ height: "inherit" }}>
        {/* <Button
          // onClick={() => setOnClickNew(true)}
          className="centered-element"
          style={{
            float: "right",
            marginLeft: "15px",
            padding: "0px 4px",
          }}
          size="xs"
          appearance="primary"
        >
          Send Email
        </Button> */}
      </Col>
    </Row>
  );

  return (
    <CustomerProvider {...useCustomerPageMethods}>
      <PageContainer>
        <PageHeader>{pageHeader}</PageHeader>
        <PageBody>
          {activeTab === "people" && <Customer />}
          {activeTab === "group" && <ContactGroup />}
        </PageBody>
      </PageContainer>
    </CustomerProvider>
  );
};

export default ContactPage;
