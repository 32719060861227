import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Row, Col, Grid, Input, TagPicker } from "rsuite";
import { notifyMessage } from "../../components/acComponents/Notification/JToast";
import _ from "lodash";
import { useCustomerContext } from "./CustomerContext";

export interface Field {
  fieldName: string;
  type: string;
  label: string;
  errorId?: string;
  value?: any;
  validator?: (value: any) => string | undefined;
}
export const groupFormConfig: Field[] = [
  { fieldName: "groupName", type: "input", label: "Group Name" },
  { fieldName: "assignedCustomers", type: "tagPicker", label: "Customers" },
];

const initialGroupFormValue = {
  firstName: "",
  assignedCustomers: [],
};

export interface GroupFormProps {
  formData: any;
}
export interface formMethods {
  saveOrUpdateGroup: (type: string) => any;
}

export const GroupForm = forwardRef<formMethods, GroupFormProps>((props, ref) => {
  const { formData } = props;
  const [fieldValue, setFieldValue] = useState<any>();
  const { updateGroup, createNewGroup, getGroupById, allCustomers } = useCustomerContext();
  const data = allCustomers.map((item: any) => ({ label: item?.firstName, value: item?.id }));
  useEffect(() => {
    async function fetchGroupById() {
      let response = await getGroupById(formData?.obj?.row?.id);
      let obj: any = {};
      obj["assignedCustomers"] = response?.data?.assignedCustomers.map((a: any) => a["id"]);
      let _fieldValue = _.assign({}, response?.data, obj);
      setFieldValue(_fieldValue);
      // setFieldValue(response?.data);
    }
    if (formData?.obj?.row?.id) {
      fetchGroupById();
    } else {
      setFieldValue(initialGroupFormValue);
    }
  }, [formData]);

  useImperativeHandle(ref, () => ({
    async saveOrUpdateGroup(type: string) {
      if (type === "edit") {
        updateGroup(fieldValue);
        notifyMessage("The group has been successfully updated", "SUCCESS");
        return true;
      } else {
        let flag = await createNewGroup(fieldValue);
        if (flag) {
          notifyMessage("The group has been successfully created", "SUCCESS");
        } else {
          notifyMessage("Group has failed to update", "ERROR");
        }
        return true;
      }
    },
  }));

  const upadteFieldValue = (key: string, value: any) => {
    if (fieldValue?.hasOwnProperty(key)) {
      let obj: any = {};
      obj[key] = value;
      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    } else {
      let obj: any = {};
      obj[key] = value;

      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    }
  };

  const inputGroup = (obj: Field, _index: number) => {
    return (
      <Row key={_index} style={{ marginBottom: "10px" }}>
        <Col xs={6}> {obj.label}</Col>
        <Col xs={18}>
          {obj?.type === "input" && (
            <Input
              value={fieldValue?.[obj.fieldName] || ""}
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
            />
          )}
          {obj?.type === "tagPicker" && (
            <TagPicker
              value={fieldValue["assignedCustomers"]}
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
              data={data}
              style={{ width: 300 }}
            />
          )}
        </Col>
      </Row>
    );
  };

  if (fieldValue === undefined) {
    return <div> Loading</div>;
  }

  return (
    <div className="sustomerForm">
      <Grid fluid>{groupFormConfig?.map((obj: any, index: any) => inputGroup(obj, index))}</Grid>
    </div>
  );
});

export default GroupForm;
