import "react-data-grid/lib/styles.css";
import { useApp } from "./hook/UseApp";
import { AppProvider } from "./context/AppContext";
import { AuthProvider } from "./context/AuthContext";
import Router from "./Router";
import "font-awesome/css/font-awesome.min.css";

function App() {
  const useAppMethods = useApp();
  // const apiUrl = process.env.REACT_APP_API_URL;
  // const env = process.env.REACT_APP_ENV;

  // console.log("%c apiUrl, :", "color:yellow", apiUrl, "- ", env);
  return (
    <AppProvider {...useAppMethods}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </AppProvider>
  );
}

export default App;
