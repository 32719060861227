import React, { FC } from "react";
import { Pagination } from "rsuite";
type LayoutType = "total" | "-" | "pager" | "|" | "limit" | "skip";
type SizeType = "lg" | "md" | "sm" | "xs";

export interface AcPaginationProps {
  /**
   * Page to start on.
   * If value is not specified, or is negative, page number will not render.
   */
  activePage?: number;
  setActivePage?: any;

  /**
   * Number of items to show on a page.
   * If value is not specified, page number will not render.
   * If specified page is greater than the actual number of pages, no page is set to current.
   */
  componentSize?: SizeType;

  limitPerPage?: number;

  /**
   * Total number of elements.
   * If value is not specified, page number will not render.
   */
  totalElements: number;

  /**
   * Handler when click on page number.
   */
  onClickPage?: (page: number) => any;

  /**
   * Total number of pages to show in pagination component.
   */
  maxButtons?: number;

  filter?: any;
}

const layoutType: LayoutType[] = ["pager"];

//const maxButtons: number = 5;
const LIMIT_OPTIONS = [30, 50, 100];

export const AcPagination: FC<AcPaginationProps> = props => {
  const {
    activePage,
    setActivePage,
    componentSize,
    limitPerPage,
    totalElements,
    onClickPage,
    maxButtons,
  } = props;
  // const [activePage, setActivePage] = React.useState(5);

  // if (page === undefined || page < 0 || !size || !totalElements) return <></>;

  // <Pagination
  //       prev
  //       last
  //       next
  //       first
  //       size="xs"
  //       total={100}
  //       limit={10}
  //       activePage={activePage}
  //       onChangePage={setActivePage}
  //     />

  //console.log("%cpaginatttttt:", "color:yellow", totalElements, "---", limitPerPage);
  return (
    <Pagination
      style={{ display: "inline-block" }}
      layout={layoutType}
      size={componentSize}
      prev={true}
      next={true}
      first={true}
      last={true}
      ellipsis={true}
      boundaryLinks={true}
      total={totalElements}
      limit={limitPerPage}
      limitOptions={LIMIT_OPTIONS}
      maxButtons={maxButtons}
      activePage={activePage}
      // onChangePage={(page: number) => onClickPage?.(page)}
      onChangePage={setActivePage}
    />
    // <Pagination
    //   prev
    //   last
    //   next
    //   first
    //   size="xs"
    //   total={totalElements}
    //   limit={limitPerPage}
    //   activePage={activePage}
    //   //onChangePage={(page: number) => onClickPage?.(page)}
    //   onChangePage={setActivePage}
    // />
  );
};

AcPagination.defaultProps = {
  maxButtons: 8,
  limitPerPage: 10,
};

AcPagination.displayName = "AcPagination";
