import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Container, Row, Sidebar, Sidenav, Content, Nav, DOMHelper, Panel } from "rsuite";
import { NavLinkProps, NavLink as BaseNavLink, Outlet } from "react-router-dom";
import Brand from "../Brand";
import NavbarItems from "../navbarItems";
import NavToggle from "../NavToggle";

import { Icon } from "@rsuite/icons";
import HomeIcon from "@rsuite/icons/legacy/Home";
import SentToUser from "@rsuite/icons/SentToUser";
import GearIcon from "@rsuite/icons/Gear";
import MemberIcon from "@rsuite/icons/Member";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import MessageIcon from "@rsuite/icons/Message";
import { AuthProvider } from "../../context/AuthContext";
import { ToastContainer } from "react-toastify";

const { getHeight, on } = DOMHelper;

export interface NavItemData {
  eventKey: string;
  title: string;
  icon?: any;
  to?: string;
  target?: string;
  children?: NavItemData[];
}

export interface DefaultLayoutProps {
  navs?: NavItemData[];
  children?: React.ReactNode;
}

export const DefaultLayout = (props: DefaultLayoutProps) => {
  const { navs } = props;
  const [expand, setExpand] = useState(true);
  const [windowHeight, setWindowHeight] = useState(getHeight(window));

  useEffect(() => {
    setWindowHeight(getHeight(window));
    const resizeListenner = on(window, "resize", () => setWindowHeight(getHeight(window)));

    return () => {
      resizeListenner.off();
    };
  }, []);

  const containerClasses = classNames("page-container", {
    "container-full": !expand,
  });

  const navBodyStyle: React.CSSProperties = expand
    ? { height: windowHeight - 112, overflow: "auto" }
    : {};

  return (
    <>
      <Row className="laout-header">
        <Brand />
        <NavbarItems />
      </Row>

      <Container className="frame">
        <Sidebar
          style={{ display: "flex", flexDirection: "column" }}
          width={expand ? 260 : 56}
          collapsible
        >
          {/* <Sidenav.Header>
          <Brand />
        </Sidenav.Header> */}
          <Sidenav expanded={expand} appearance="subtle" defaultOpenKeys={["2", "3"]}>
            <Sidenav.Body style={navBodyStyle}>
              <Nav>
                <Nav.Item as={BaseNavLink} key="dashboard" to="/dashboard" icon={<HomeIcon />}>
                  Dashboard
                </Nav.Item>
                <Nav.Item as={BaseNavLink} key="sent" to="/sent" icon={<SentToUser />}>
                  Sent
                </Nav.Item>
                <Nav.Item as={BaseNavLink} key="contact" to="/contact" icon={<MemberIcon />}>
                  Contacts
                </Nav.Item>
                <Nav.Item as={BaseNavLink} key="email" to="/email" icon={<EmailFillIcon />}>
                  Email Template
                </Nav.Item>
                <Nav.Item as={BaseNavLink} key="sms" to="/sms" icon={<MessageIcon />}>
                  SMS Template
                </Nav.Item>
                <Nav.Item as={BaseNavLink} key="fmr" to="/filemanager" icon={<GearIcon />}>
                  File manager
                </Nav.Item>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
          <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
        </Sidebar>

        <Container className={containerClasses}>
          {/* <ToastContainer
            position="bottom-right"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          /> */}
          
          <Content>
            <div className="layout-page">
              <Outlet />
            </div>
          </Content>
        </Container>
      </Container>
      <ToastContainer
      position="bottom-right"
      autoClose={8000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    </>
  );
};

DefaultLayout.displayName = "DefaultLayout";
