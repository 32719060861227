const UU_JWT = "UU_JWT";
export default class AuthStorage {
  static async saveToken(data: any) {
    localStorage.setItem(UU_JWT, JSON.stringify(data));
  }

  static deleteToken() {
    localStorage.removeItem(UU_JWT);
  }

  static getToken() {
    const JWT = localStorage.getItem(UU_JWT);
    return JWT;
  }

  static getAuthorization() {
    const obj = localStorage.getItem(UU_JWT);
    const parObj = obj ? JSON.parse(obj) : null;
    return parObj ? "Bearer " + parObj?.accessToken : null;
  }

  // static parseJwt(token: string) {
  //   var base64Url = token.split(".")[1];
  //   var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //   var jsonPayload = decodeURIComponent(
  //     window
  //       .atob(base64)
  //       .split("")
  //       .map(function (c) {
  //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join("")
  //   );

  //   return JSON.parse(jsonPayload);
  // }
  static parseJwt() {
    const obj = localStorage.getItem(UU_JWT);
    const parObj = obj ? JSON.parse(obj) : null;
    return parObj ? parObj?.accessToken : null;
  }
}
