import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Row, Col, Grid, Input, TagPicker } from "rsuite";
import { notifyMessage } from "../../components/acComponents/Notification/JToast";
import _ from "lodash";
import { useCustomerContext } from "./CustomerContext";

export interface Field {
  fieldName: string;
  type: string;
  label: string;
  errorId?: string;
  value?: any;
  validator?: (value: any) => string | undefined;
}
export const customerFormConfig: Field[] = [
  { fieldName: "firstName", type: "input", label: "First Name" },
  { fieldName: "lastName", type: "input", label: "Last Name" },
  { fieldName: "company", type: "input", label: "Company" },
  { fieldName: "email", type: "input", label: "Email" },
  { fieldName: "phoneCode", type: "input", label: "Phone Code" },
  { fieldName: "phone", type: "input", label: "Phone" },
  { fieldName: "address", type: "input", label: "Address" },
  { fieldName: "city", type: "input", label: "City" },
  { fieldName: "state", type: "input", label: "State" },
  { fieldName: "country", type: "input", label: "Country" },
  { fieldName: "postalCode", type: "input", label: "Postal Code" },
  { fieldName: "assignedGroups", type: "tagPicker", label: "Groups" },
];

// export interface CustomerForm {
//   firstName?: string;
//   lastName?: string;
//   company?: string;
//   email?: string;
//   phoneCode?: string;
//   phone?: string;
//   address?: string;
//   city?: string;
//   state?: string;
//   country?: string;
//   postalCode?: string;
//   assignedGroups?: number[];
// }

const initialCustomerFormValue = {
  firstName: "",
  lastName: "",
  company: "",
  email: "",
  phoneCode: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
  assignedGroups: [],
};

export interface CustomerFormProps {
  formData: any;
}
export interface formMethods {
  saveOrUpdateCustomer: (type: string) => any;
}

export const CustomerForm = forwardRef<formMethods, CustomerFormProps>((props, ref) => {
  const { formData } = props;
  const [fieldValue, setFieldValue] = useState<any>();
  const { updateCustomer, createNewCustomer, allGroups, getCustomerById } = useCustomerContext();
  const data = allGroups.map((item: any) => ({ label: item?.groupName, value: item?.id }));

  useEffect(() => {
    async function fetchCustomerById() {
      let response = await getCustomerById(formData?.obj?.row?.id);
      let obj: any = {};
      obj["assignedGroups"] = response?.data?.data?.assignedGroups.map((a: any) => a["id"]);
      let _fieldValue = _.assign({}, response?.data?.data, obj);
      setFieldValue(_fieldValue);
      //setFieldValue(response?.data?.data);
    }
    if (formData?.obj?.row?.id) {
      fetchCustomerById();
    } else {
      setFieldValue(initialCustomerFormValue);
    }
  }, [formData]);

  useImperativeHandle(ref, () => ({
    async saveOrUpdateCustomer(type: string) {
      let returnFlg = false;
      if (type === "edit") {
        let data = await updateCustomer(fieldValue);    
        if (data?.data?.hasData) {
          notifyMessage("The contact has been successfully updated", "SUCCESS");
          returnFlg = true;       
        } else {
          notifyMessage( data?.errorMessage?.error?.message, "ERROR");
        }
        
      } else {
        let data = await createNewCustomer(fieldValue);
        if (data?.data?.hasData) {
          returnFlg = true;
          notifyMessage("The contact has been successfully created", "SUCCESS");          
        } else {
          notifyMessage( data?.errorMessage?.error?.message, "ERROR");
        }
      }

      return returnFlg;
    },
  }));

  const upadteFieldValue = (key: string, value: any) => {
    if (fieldValue?.hasOwnProperty(key)) {
      let obj: any = {};
      obj[key] = value;
      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    } else {
      let obj: any = {};
      obj[key] = value;
      const _fieldValue = _.assign({}, fieldValue, obj);
      setFieldValue(_fieldValue);
    }
  };

  const inputGroup = (obj: Field, _index: number) => {
    return (
      <Row key={_index} style={{ marginBottom: "10px" }}>
        <Col xs={6}> {obj.label}</Col>
        <Col xs={18}>
          {obj?.type === "input" && (
            <Input
              value={fieldValue?.[obj.fieldName] || ""}
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
            />
          )}
          {obj?.type === "tagPicker" && (
            <TagPicker
              value={fieldValue["assignedGroups"]}
              onChange={(value: string | number | readonly string[], _event) =>
                upadteFieldValue(obj.fieldName, value)
              }
              data={data}
              style={{ width: 300 }}
            />
          )}
        </Col>
      </Row>
    );
  };

  if (fieldValue === undefined) {
    return <div> Loading</div>;
  }
  return (
    <div className="sustomerForm">
      <Grid fluid>{customerFormConfig?.map((obj: any, index: any) => inputGroup(obj, index))}</Grid>
    </div>
  );
});

export default CustomerForm;
