import React, {FC, useRef, useState, useEffect } from 'react';
import { SelectPicker, Button, DatePicker, Input , Row, Col,} from 'rsuite';
import { Icon } from '@rsuite/icons';
import './styles.less';

function useOutsideAlerter(ref: any, setIsOpenFn: any) {

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (
          typeof event.target?.className === 'string' &&
          !event.target?.className?.includes('s-filter')
        ) {
          if (
            !event.target?.className?.includes('rs-picker-select-menu') &&
            !event.target?.className?.includes('rs-calendar') &&
            !(event?.target.innerText === 'Today') &&
            !(event?.target.innerText === 'Yesterday')
          )
            setIsOpenFn(false);
        } else if (
          event?.srcElement instanceof SVGElement &&
          !event?.srcElement?.getAttribute('aria-label')?.includes('angle left') &&
          !event?.srcElement?.getAttribute('aria-label')?.includes('angle right') &&
          !event?.srcElement?.getAttribute('d')?.includes('M11.') &&
          !event?.srcElement?.getAttribute('d')?.includes('M10.')
        ) {
          setIsOpenFn(false);
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}


const FilterBar: FC<any> =  props => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsOpen);
  const [keyword, setKeyword] = useState<any>(props?.filterObj);


  const doSearch = () => {
    props?.setFilterObj(keyword);
    props?.setCallPage((prevIsLoading: any) => !prevIsLoading);

    setIsOpen(!isOpen);
  };
  const closeFilter = () => {
    setIsOpen(!isOpen);
  };

  const resetFilter = () => {

    if(keyword?.length){
      setKeyword("");
    }
    if(props?.filterObj?.length){
      props?.setFilterObj("");
      props?.setCallPage((prevIsLoading: any) => !prevIsLoading);
    }
  };


  return (
    <>
      <Button
        className="s-filter"
        style={{
          float: 'right',
          border: '1px solid #ccc',
          marginLeft: '15px',
          padding: '0px 4px',
        }}
        size="xs"
        onClick={toggleMenu}
      >
        { !!props?.filterObj?.length &&  <i className="fas fa-solid fa-circle" style={{color: "red", paddingRight:'4px'}}></i>}
        <i className="fas fa-sliders icon-text s-filter"></i>
        <span className="icon-text s-filter">Filter</span>
      </Button>

      {isOpen === true && (
      <div className="dataResult dropdown-menu-right" ref={wrapperRef}>
        <Row style={{ marginBottom: "10px", marginTop: "10px", marginLeft: "10px", }}>
         <Col style={{ margin: "10px", fontWeight:"bold" }} xs={4}> Filter</Col>
         <Col xs={18}>
          <Input
              value={keyword}
              onChange={(value: string | number | readonly string[], _event) =>
                setKeyword(value)
              }
            />
          </Col>
        </Row>       
        <Row style={{ marginBottom: "10px", marginTop: "10px", marginLeft: "10px", }}>
         <Col style={{ margin: "10px" }} xs={4}> </Col>
         <Col xs={18}>
         <Button
         onClick={doSearch}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="primary"
          >
            Search
          </Button>
          <Button
         onClick={resetFilter}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="ghost"
          >
            Clear
          </Button>

          <Button
         onClick={closeFilter}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="ghost"
          >
            Close
          </Button>

          </Col>
        </Row>    
          {/* <SelectPicker
            className="s-filter"
            data={[
              { label: 'xx', value: 'xyz' },
              { label: 'yy', value: 'xyzss' },
            ]}
            cleanable={false}
            searchable={false}
          />
          <DatePicker id="filter-date-picker" oneTap={true} cleanable={false} /> */}
        </div>
      )}
    </>
  );
};

export default FilterBar;
