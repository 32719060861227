import { ApiRestService } from "../../common/ApiRestService";
import AuthStorage from "../../../context/AuthStorage";

export class LoginService extends ApiRestService {
  toHeaders() {
    let headers: Record<string, any> = {};
    headers["Authorization"] = AuthStorage.getAuthorization();
    return headers;
  }

  async login(_usernameOrEmail: string, _password: string) {
    const _body = {
      usernameOrEmail: _usernameOrEmail,
      password: _password,
    };

    return this.post({
      baseURL: "http://localhost:8070/api",
      path: "/auth/signin",
      body: _body,
      headers: this.toHeaders(),
      apiName: "login-request",
    });

    // return {
    //   companyPublish: true,
    //   enableCustomDomain: false,
    //   data: {
    //     userId: 8,
    //     otpMsg: "OTP sent successfully.",
    //   },
    //   error: null,
    //   hasData: true,
    //   hasError: false,
    // };
  }

  async sendOTP(payload: any) {
    return this.post({
      baseURL: "http://localhost:8070/api",
      path: "/auth/validate/OTP",
      body: payload,
      headers: this.toHeaders(),
      apiName: "login-request",
    });

    // return {
    //   companyPublish: true,
    //   enableCustomDomain: false,
    //   data: {
    //     accessToken:
    //       "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4IiwiaWF0IjoxNjkxMzM5ODg5LCJleHAiOjE2OTE5NDQ2ODl9.COadnlYVHmygmhmM12uD8WBvtBrOHLkWC9U__C9E8fbThT3exPR-tWpBJiNkZgu7jurjBFokpoyO165OoybHxA",
    //     tokenType: "Bearer",
    //     name: "jithux",
    //     email: "jithu.majinu@gmail.com",
    //   },
    //   error: null,
    //   hasData: true,
    //   hasError: false,
    // };
  }

  async changePassword(_oldPassword: string, _newPassword: string) {
    const _body = {
      oldPassword: _oldPassword,
      newPassword: _newPassword,
    };
    return this.post({
      apiName: "login-request",
      baseURL: "http://localhost:8070/api",
      path: "/auth/password-change",
      body: _body,
      headers: this.toHeaders(),
    });
  }
}
