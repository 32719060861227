import React, { FC, useState, useMemo, useEffect, MouseEvent, useRef } from "react";
import { Row, Col, Button, Drawer, Whisper, IconButton, Tooltip } from "rsuite";
import { Icon } from "@rsuite/icons";
import { FaRegEdit } from "react-icons/fa";
import { PageContainer, PageHeader, PageBody } from "../../components/layout";
import { EmailProvider, useEmailContext } from "./EmailContext";
import { useEmailPage } from "./UseEmailPage";
import DataGrid, { SelectColumn } from "react-data-grid";
import type { Column, RowsChangeData, DataGridHandle } from "react-data-grid";
import AcPageList from "../../components/acComponents/pageList";
import { ApiService } from "../../apiServices";
import { createApiPageRequest } from "../../apiServices/common/domain/PageUtil";
import { notifyMessage, dismissMessage } from "../../components/acComponents/Notification/JToast";
import { EmailForm, formMethods } from "./EmailForm";

export interface EmailPageProps {}

export const EmailPage: FC<EmailPageProps> = props => {
  const useEmailPageMethods = useEmailPage({ businessCalendar: "test" });

  const {
    isLoading,
    emailTemplateData,
    limitPerPage,
    setLimitPerPage,
    activePage,
    setActivePage,
    deleteEmailTemplate,
  } = useEmailPageMethods;

  const formRef = useRef<formMethods>(null);
  const [emailTemplateFormValue, setEmailTemplateFormValue] = useState<any>();
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<Set<number>>(() => new Set());
  const [sortDirection, setSortDirection] = useState<any>();
  const [open, setOpen] = React.useState(false);

  const handleRowClickEdit = (clickObj: any) => {
    setFormValue?.({ type: "edit", obj: clickObj });
  };

  const columns = useMemo((): Column<any>[] => {
    return [
      SelectColumn,
      {
        key: "Action",
        name: "Action",
        resizable: true,
        width: 100,
        formatter(props: any) {
          return (
            <>
              <Whisper placement="top" trigger="hover" speaker={<Tooltip>Edit</Tooltip>}>
                <IconButton
                  size="xs"
                  appearance="primary"
                  onClick={() => handleRowClickEdit(props)}
                  icon={<Icon as={FaRegEdit} />}
                />
              </Whisper>
            </>
          );
        },
      },
      { key: "id", name: "Id", width: 35 },
      { key: "templateName", name: "Template Name", resizable: true },
      // { key: "templateContent", name: "Content", resizable: true },
    ];
  }, []);

  const setFormValue = (clickObj: any) => {
    setOpen(true);
    setEmailTemplateFormValue(clickObj);
  };

  const createOrUpdateHandle = async () => {
    if (formRef?.current) {
      const status = await formRef.current.saveOrUpdateEmailTemplate(emailTemplateFormValue?.type);
      if (status) {
        setOpen(false);
      }
    }
  };

  const handleDeleteByIds = async () => {
    let selectedIds = Array.from(selectedEmailTemplate);

    const status = await deleteEmailTemplate(selectedIds);
    if (status) {
      setOpen(false);
    }
  };

  const pageHeader = (
    <Row className="show-grid" style={{ height: "inherit" }}>
      <Col xs={6}>
        <h3 className="title"> Email Template </h3>
      </Col>
    </Row>
  );

  return (
    <EmailProvider {...useEmailPageMethods}>
      <PageContainer>
        <PageHeader>{pageHeader}</PageHeader>
        <PageBody>
          <Row style={{ marginBottom: "10px" }}>
            <Col xs={6}>
              <Button
                onClick={() => setFormValue({ type: "new", formObj: undefined })}
                style={{
                  marginRight: "15px",
                  padding: "0px 4px",
                }}
                size="xs"
                appearance="primary"
                disabled={!!selectedEmailTemplate?.size}
              >
                <i className="fas   fa-plus"></i> New
              </Button>
              <Button
                onClick={handleDeleteByIds}
                style={{
                  marginRight: "15px",
                  padding: "0px 4px",
                }}
                disabled={!selectedEmailTemplate?.size}
                size="xs"
                appearance="primary"
              >
                Delete
              </Button>
            </Col>
            <Col xs={18}></Col>
          </Row>
          <AcPageList
            columns={columns}
            data={emailTemplateData}
            setLimitPerPage={setLimitPerPage}
            setActivePage={setActivePage}
            selected={selectedEmailTemplate}
            setSelected={setSelectedEmailTemplate}
            setSortDirection={setSortDirection}
            setFormValue={setFormValue}
            limitPerPage={limitPerPage}
            pagination={false}
            whenNoData={
              <>
                <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
              </>
            }
            loading={isLoading}
          >
            <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
          </AcPageList>

          <Drawer
            keyboard={true}
            size="full"
            backdrop={"static"}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <Drawer.Header>
              <Drawer.Title>Email</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <EmailForm ref={formRef} formData={emailTemplateFormValue} />

              <Drawer.Actions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    createOrUpdateHandle();
                  }}
                  appearance="primary"
                >
                  {emailTemplateFormValue?.type === "edit" ? "Update" : "Create"}
                </Button>
              </Drawer.Actions>
            </Drawer.Body>
          </Drawer>
        </PageBody>
      </PageContainer>
    </EmailProvider>
  );
};

export default EmailPage;
