import { useState, FC } from "react";
import { NavLink as BaseNavLink, useParams } from "react-router-dom";
import { Row, Nav, Col } from "rsuite";
import { PageContainer, PageHeader, PageBody } from "../../components/layout";
import { DashboaedSms } from "./DashboaedSms";
import { DashboaedEmail } from "./DashboaedEmail";

//const SentItems = () => {
export const SentItems: FC<any> = props => {
  const routeParams = useParams();
  const [activeTab, setActiveTab] = useState(routeParams.path || "sms");
  const pageHeader = (
    <Row className="show-grid" style={{ height: "inherit" }}>
      <Col xs={24}>
        <Nav
          appearance="subtle"
          activeKey={activeTab}
          onSelect={setActiveTab}
          style={{ marginTop: 4 }}
        >
          <Nav.Item as={BaseNavLink} to="/sent/sms" eventKey="sms">
            <i className="fas fa-users"></i> Sms
          </Nav.Item>
          <Nav.Item as={BaseNavLink} to="/sent/email" eventKey="email">
            <i className="fas fa-building"></i> Email
          </Nav.Item>
        </Nav>
      </Col>
    </Row>
  );

  return (
    <PageContainer>
      <PageHeader>{pageHeader}</PageHeader>
      <PageBody>
        {activeTab === "sms" && <DashboaedSms />}
        {activeTab === "email" && <DashboaedEmail />}
      </PageBody>
    </PageContainer>
  );
};

SentItems.displayName = "SentItems";
// export default SentItems;
