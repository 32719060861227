import React, { FC, useState, useMemo, useRef } from "react";
import { Row, Col, Button, Drawer, Modal, Whisper, IconButton, Tooltip } from "rsuite";
import { Icon } from "@rsuite/icons";
import { FaRegEdit } from "react-icons/fa";
import { SelectColumn } from "react-data-grid";
import type { Column } from "react-data-grid";
import AcPageList from "../../components/acComponents/pageList";
import { CustomerForm, formMethods } from "./CustomerForm";
import { SmsComposeModal } from "./SmsComposeModal";
import { EmailComposeModal } from "./EmailComposeModal";
import { useCustomerContext } from "./CustomerContext";
import { FileUploadForm, uploadFormMethods } from "./FileUploadForm";
import Moment from 'react-moment';

export interface CustomerProps {}
export const Customer: FC<CustomerProps> = props => {
  const {
    isLoading,
    customerPageData,
    limitPerPage,
    setLimitPerPage,
    setActivePage,
    activePage,
    deleteCustomers,
    exportCustomer, 
    setFilterObj,
    filterObj,
    setCallPage,
  } = useCustomerContext();
  const formRef = useRef<formMethods>(null);
  const uploadFormRef = useRef<uploadFormMethods>(null);
  const [customersFormValue, setCustomersFormValue] = useState<any>();
  const [selectedCustomers, setSelectedCustomers] = useState<Set<number>>(() => new Set());
  const [sortDirection, setSortDirection] = useState<any>();
  const [open, setOpen] = React.useState(false);

  const handleRowClickEdit = (clickObj: any) => {
    setFormValue?.({ type: "edit", obj: clickObj });
  };

  const columns = useMemo((): Column<any>[] => {
    return [
      SelectColumn,
      {
        key: "Action",
        name: "Action",
        resizable: true,
        width: 100,
        formatter(props: any) {
          return (
            <>
              <Whisper placement="top" trigger="hover" speaker={<Tooltip>Edit</Tooltip>}>
                <IconButton
                  size="xs"
                  appearance="primary"
                  onClick={() => handleRowClickEdit(props)}
                  icon={<Icon as={FaRegEdit} />}
                />
              </Whisper>
            </>
          );
        },
      },
      { key: "id", name: "Id", width: 35 },
      { key: "firstName", name: "First Name", width: 180, resizable: true },
      { key: "lastName", name: "Last Name", width: 180, resizable: true },
      { key: "company", name: "Company", width: 200, resizable: true },
      { key: "email", name: "Email", width: 200, resizable: true },
      { key: "phoneCode", name: "Phone Code", width: 200, resizable: true },
      { key: "phone", name: "Phone", width: 200, resizable: true },
      { key: "address", name: "Address", width: 200, resizable: true },
      { key: "city", name: "City", width: 200, resizable: true },
      { key: "state", name: "State", width: 200, resizable: true },
      { key: "country", name: "Country", width: 200, resizable: true },
      { key: "postalCode", name: "Postal Code", width: 200, resizable: true },
      {
        key: "mailUnsubscribed",
        name: "Unsubscribed",
        width: 60,

        formatter(props: any) {
          return <>{props?.row?.mailUnsubscribed ? <span style={{ color:"red"}}> <i className="fas fa-solid fa-circle" style={{color: "red", paddingRight:'4px'}}></i>
                   Yes</span> : 
                   <span style={{ color:"green"}}> <i className="fas fa-solid fa-circle" style={{color: "green", paddingRight:'4px'}}></i>
                   No</span>
                   }</>;
        },
        resizable: true,
      },
    ];
  }, []);

  const setFormValue = (clickObj: any) => {
    setOpen(true);
    setCustomersFormValue(clickObj);
  };

  const createOrUpdateHandle = async () => {
    if (formRef?.current) {
      try {
        const status = await formRef.current.saveOrUpdateCustomer(customersFormValue?.type);
        if (status) {
          setOpen(false);
        }
      } catch (e) {}
    }
  };

  const handleDeleteByIds = async () => {
    let selectedIds = Array.from(selectedCustomers);

    const status = await deleteCustomers(selectedIds);
    if (status) {
      setOpen(false);
    }
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [modalType, setModalType] = useState<any>();
  const [modalTitle, setModalTitle] = useState<string>("");

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalType(null);
    setModalTitle("");
  };

  const handleOpenModal = (value: string, title: string) => {
    setModalType(value);
    setModalTitle(title);
    setOpenModal(true);
  };

  const handleExportConatcts = async () => {
    try {
      await exportCustomer();
    } catch (e) {}
  };

  return (
    <>
      <Row style={{ marginBottom: "10px" }}>
        <Col xs={14}>
          <Button
            onClick={() => setFormValue({ type: "new", formObj: undefined })}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="primary"
            disabled={!!selectedCustomers?.size}
          >
            <i className="fas   fa-plus"></i> New
          </Button>
          <Button
            onClick={handleDeleteByIds}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            disabled={!selectedCustomers?.size}
            size="xs"
            appearance="primary"
          >
            Delete
          </Button>

          <Button
            onClick={() => handleOpenModal("email", "Send Email")}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="primary"
            disabled={!selectedCustomers?.size}
          >
            Send Email
          </Button>
          <Button
            onClick={() => handleOpenModal("sms", "Send SMS")}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="primary"
            disabled={!selectedCustomers?.size}
          >
            Send Sms
          </Button>

          <Button
            onClick={() => handleExportConatcts()}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="ghost"
          >
            Download Contacts
          </Button>
          <Button
            onClick={() => handleOpenModal("uploadContact", "Upload Contacts")}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="ghost"
          >
            Upload Contacts
          </Button>
         
        </Col>
        {/* <Col xs={4}>

        <Button
            onClick={() => handleOpenModal("uploadContact", "Upload Contacts")}
            style={{
              marginRight: "15px",
              padding: "0px 4px",
            }}
            size="xs"
            appearance="ghost"
          >
            Search
          </Button>
        </Col> */}
      </Row>
      <AcPageList
        columns={columns}
        data={customerPageData?.content}
        pageInfo={customerPageData}
        setLimitPerPage={setLimitPerPage}
        setActivePage={setActivePage}
        setCallPage={setCallPage}
        filter={true}
        activePage={activePage}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        selected={selectedCustomers}
        setSelected={setSelectedCustomers}
        setSortDirection={setSortDirection}
        setFormValue={setFormValue}
        limitPerPage={limitPerPage}
        pagination={true}
        whenNoData={
          <>
            <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
          </>
        }
        loading={isLoading}
      >
        <p className="text-center fs-18 font-weight-bold mb-4">No data</p>
      </AcPageList>
      <Drawer
        keyboard={true}
        size="sm"
        backdrop={"static"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Drawer.Header>
          <Drawer.Title>Contact</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <CustomerForm ref={formRef} formData={customersFormValue} />

          <Drawer.Actions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                createOrUpdateHandle();
              }}
              appearance="primary"
            >
              {customersFormValue?.type === "edit" ? "Update" : "Create"}
            </Button>
          </Drawer.Actions>
        </Drawer.Body>
      </Drawer>

      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        open={openModal}
        onClose={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>
            {modalTitle}

            {/* {modalType === "sms" ? "Send SMS" : "Send Email"} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "sms" && (
            <SmsComposeModal onModalClose={handleCloseModal} selectedCustomerIds={Array.from(selectedCustomers)} />
          )}
          {modalType === "email" && (
            <EmailComposeModal
              onModalClose={handleCloseModal}
              selectedCustomerIds={Array.from(selectedCustomers)}
            />
          )}
          {modalType === "uploadContact" && (
            <FileUploadForm onModalClose={handleCloseModal} ref={uploadFormRef} />
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={handleCloseModal} appearance="primary">
            {modalType === "sms" ? "Send SMS" : "Send Email"}
          </Button>
          <Button onClick={handleCloseModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Customer;
