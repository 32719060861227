import React, { FC } from 'react';

interface AcLoadingProps {
  disableBlock?: boolean;
}

export const AcLoading: FC<AcLoadingProps> = ({ disableBlock }) => {
  return <div className="lds-dual-ring"></div>;
  //return <div className="loader"></div>;
};

export default AcLoading;
